import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';

import ResultGameComponent from "./ResultGameComponent";

import {gamesSocket} from "../store/selectors";
import {appGetInterfaceLang} from "../../../store/selectors";
import {uiRedirect} from "../../../store/actions";
import isEqual from "lodash-es/isEqual";

const stateToProps = state => ({
    gamesSocket: gamesSocket(state),
    appGetInterfaceLang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({
    uiRedirect: (url) => dispatch(uiRedirect(url)),
});

@connect(stateToProps, actionsToProps)
class ResultGameContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            imagesLoaded: false,
            challengePlay: false,
        };

        if(!this.props.location.state || !this.props.location.state.finish || !this.props.location.state.challengePlay) {
            this.props.uiRedirect({
                pathname: `/challenge/instructions/${this.props.match.params.challengeId}`
            })
        }
    }

    handlePreloadImages = () => {
        this.setState({
            imagesLoaded: true
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!isEqual(prevState.challengePlay, this.props.location.state.challengePlay) && this.props.location.state.challengePlay) {
            this.setState({
                challengePlay: this.props.location.state.challengePlay
            })
            if(this.props.location.state.challengePlay.name) {
                this.props.getTitle(this.props.location.state.challengePlay.name[this.props.appGetInterfaceLang])
            }
        }
    }

    render() {
        return (
            <ResultGameComponent
              imagesLoaded={this.state.imagesLoaded}
              handlePreloadImages={this.handlePreloadImages}
              challengePlay={this.state.challengePlay}
            />
        );
    }
}

export default ResultGameContainer;