import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import Timer from "react-compound-timer";
import getText from '../../../../../utils/language';

import SwipeItComponent from "./SwipeItComponent";
import logger from 'AppUtils/logging';

import {uiRedirect, uiToggleModal} from "../../../../../store/actions";
import {
	socketLoadCloseConnection,
	socketLoadFinishChallenge,
	socketLoadStartChallenge,
	socketLoadUpdateChallenge
} from "../../../store/actions";
import {gamesSocket} from "../../../store/selectors";
import isEqual from "lodash-es/isEqual";
import {appGetInterfaceLang} from "../../../../../store/selectors";
import {userInfo} from "../../../../user/store/selectors";
import {userLoadInfo, userLoadUpdateInfo} from "../../../../user/store/actions";

const stateToProps = state => ({
	gamesSocket: gamesSocket(state),
	appGetInterfaceLang: appGetInterfaceLang(state),
	userInfo: userInfo(state),
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	userLoadInfo: () => dispatch(userLoadInfo()),
	socketLoadUpdateChallenge: (data) => dispatch(socketLoadUpdateChallenge(data)),
	socketLoadStartChallenge: () => dispatch(socketLoadStartChallenge()),
	socketLoadFinishChallenge: () => dispatch(socketLoadFinishChallenge()),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	socketLoadCloseConnection: () => dispatch(socketLoadCloseConnection()),
});

@connect(stateToProps, actionsToProps)
class SwipeItContainer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			challengePlay: false,
			imagesLoaded: false,
			windowHeight: false,
			timeCritical: false,
			isOpen: false,
			images: ['http://placekitten.com/200/300', 'http://placekitten.com/200/300', 'http://placekitten.com/200/300', 'http://placekitten.com/200/300'],
			index: 0,
			dir: null,
			lastItemValid: null,
			answers: [],
			secondsNegative: 0,
			initialCards: 0,
			runHint: this.props.runHint,
			availableSkills: props.userInfo.character.skills.items[this.props.location.state.gameType].available,
			preventClick: false
		}

		if(!this.props.location.start) {
			this.props.uiRedirect({
				pathname: `/map`
			})
		} else if(!this.props.match.params.challengeId) {
			this.props.uiRedirect({
				pathname: '/map'
			})
		}

		this.interval = false;
	}

	static getDerivedStateFromProps(props, current_state) {
		if (current_state.runHint !== props.runHint) {
			return {
				runHint: props.runHint,
			}
		}
		return null
	}

	openModal = (title, message, button, buttonText, button2, button2Text, restrictClose, callback, callback2) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, restrictClose, callback } );
	};

	closeModal = () => {
		this.props.uiToggleModal({}, { toggle: false }, { title: '', message: '' });
	};

	countNegative = () => {
		let stateCopy = {...this.state};
		this.interval = setInterval(() => {
			if(this.state.pauseNegative) {
				// clearInterval(interval)
			} else {
				this.setState({
					secondsNegative: stateCopy.secondsNegative--
				})
			}
		}, 1000);

	}

	handlePreloadImages = () => {
		this.setState({
			imagesLoaded: true
		})
	}

	handleControl = () => {
		logger.info('handle control')
		this.setState({
			index: this.state.index - 1
		})
	}

	// changeIndex = () => {
	// 	this.setState({
	// 		index: this.state.index - 1
	// 	})
	// }

	validateCard = (index, dir, isHint) => {

		if(isHint) {
			this.props.handleHint(false, true);
		}

		let finalCountdown = parseInt(this.props.timer.getTime()/1000);

		if(finalCountdown <= 0) {
			finalCountdown = this.state.secondsNegative;
		}

		this.props.timer.pause();

		this.setState({
			currentDir: dir,
			pauseNegative: true,
			isHint: isHint,
			preventClick: true,
		}, () => {
			if(!isHint) {
				let answerDir = dir === 1 ? 'right' : 'left';
				this.props.socketLoadUpdateChallenge({ challengeCurrentRound: this.state.challengePlay.current_round, answer: answerDir, countdown: finalCountdown });
			} else {
				this.props.socketLoadUpdateChallenge({ challengeCurrentRound: this.state.challengePlay.current_round, autosolve: true, countdown: finalCountdown });
			}
		})
	}

	updateWindowDimensions = () => {
		this.setState({ windowHeight: window.innerHeight });
	};

	returnTimeLeft = () => {
		return (
		  <>
			  <Timer.Minutes formatValue={value => ("0" + value).slice(-2)} />:<Timer.Seconds formatValue={value => ("0" + value).slice(-2)} />
		  </>
		);
	}

	componentDidMount() {
		this.updateWindowDimensions();

		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.gamesSocket.error !== this.props.gamesSocket.error && this.props.gamesSocket.error) {
			if(this.state.isHint) {
				this.props.handleHint(false, false);
			}
			this.openModal(getText('Error'), getText('Something went wrong. Please try again later.'), true, getText('Retry'), false, false, true, () => {
				this.props.uiRedirect({
					pathname: `/map`
				})
				this.closeModal();
			});
		} else if(!this.props.gamesSocket.error) {
			if (!isEqual(prevProps.gamesSocket, this.props.gamesSocket) && this.props.gamesSocket && this.props.gamesSocket.data && this.props.gamesSocket.data.challengePlay && this.props.gamesSocket.call === 'start_challenge') {
				this.props.checkGameReady(true);

				let answers = [];
				for(let i = 0; i < this.props.gamesSocket.data.challengePlay.total_rounds; i++) {
					if(typeof this.props.gamesSocket.data.challengePlay.rounds_status[i] !== "undefined") {
						answers[i] = this.props.gamesSocket.data.challengePlay.rounds_status[i];
					} else {
						answers[i] = null;
					}
				}

				if(parseInt(this.props.gamesSocket.data.challengePlay.timer) <= 0) {
					this.setState({
						secondsNegative: this.props.gamesSocket.data.challengePlay.timer
					}, () => {
						this.countNegative();
					})
				}

				let challengePlayCopy = {...this.props.gamesSocket.data.challengePlay};
				challengePlayCopy.rounds = challengePlayCopy.rounds.map((item,idx) => challengePlayCopy.rounds[challengePlayCopy.rounds.length-1-idx]);

				this.setState({
					challengePlay: challengePlayCopy,
					answers,
					index: (this.props.gamesSocket.data.challengePlay.total_rounds - 1) - this.props.gamesSocket.data.challengePlay.current_round,
					initialCards: this.props.gamesSocket.data.challengePlay.total_rounds - this.props.gamesSocket.data.challengePlay.current_round
				})

				this.props.timer.setCheckpoints([
					{
						time: 6000,
						callback: () => {
							this.setState({
								timeCritical: true
							})
						},
					},
					{
						time: 0,
						callback: () => {
							this.countNegative();
						},
					},
				]);

				this.props.timer.setTime((this.props.gamesSocket.data.challengePlay.timer <= 0 ? 0 : this.props.gamesSocket.data.challengePlay.timer * 1000 + 500));

				this.props.getTitle(this.props.gamesSocket.data.challengePlay.name[this.props.appGetInterfaceLang])
			}

			if(!isEqual(prevProps.gamesSocket.data, this.props.gamesSocket.data) && this.props.gamesSocket && this.props.gamesSocket.data && this.props.gamesSocket.data.challengePlay && this.props.gamesSocket.call === 'update_challenge') {
				let answers = [...this.state.answers];

				answers[this.props.gamesSocket.data.challengePlay.current_round - 1] = this.props.gamesSocket.data.challengePlay.rounds_status[this.props.gamesSocket.data.challengePlay.current_round - 1];

				if(prevProps.gamesSocket.data.challengePlay.current_round + 1 === this.props.gamesSocket.data.challengePlay.total_rounds) {
					answers[this.props.gamesSocket.data.challengePlay.current_round] = this.props.gamesSocket.data.challengePlay.rounds_status[this.props.gamesSocket.data.challengePlay.current_round];
				}

				if(this.state.isHint) {
					this.props.userLoadInfo();
				}

				let challengePlayCopy = {...this.props.gamesSocket.data.challengePlay};
				challengePlayCopy.rounds = challengePlayCopy.rounds.map((item,idx) => challengePlayCopy.rounds[challengePlayCopy.rounds.length-1-idx]);

				this.setState({
					challengePlay: challengePlayCopy,
					lastItemValid: this.props.gamesSocket.data.challengePlay.rounds_status[this.props.gamesSocket.data.challengePlay.rounds_status.length - 1],
					answers,
				}, () => {
					console.log(this.state.lastItemValid, 'this.state.lastItemValid=======================0')

					setTimeout(() => {
						if(this.props.gamesSocket.data.challengePlay.rounds_status.length === this.props.gamesSocket.data.challengePlay.total_rounds) {
							this.props.socketLoadFinishChallenge();
						} else {
							if(this.props.timer.getTime() > 0) {
								this.props.timer.start();
							}

							console.log(this.state.lastItemValid, 'this.state.lastItemValid======================1')
							this.setState({
								lastItemValid: null,
								dir: null,
								isHint: false,
								pauseNegative: false,
								preventClick: false
							})
							this.props.handleHint(false, false);
						}
					}, 600)
				})
			}

			if(!isEqual(prevProps.gamesSocket, this.props.gamesSocket) && this.props.gamesSocket.gameFinished) {
				this.props.handleHint(false, false);
				this.props.uiRedirect({
					pathname: `/result-game/${this.state.challengePlay.challenge_id}`,
					state: {
						finish: true,
						challengePlay: this.props.gamesSocket.data.challengePlay
					}
				})
			}
		}

		if(prevState.imagesLoaded !== this.state.imagesLoaded && this.state.imagesLoaded) {
			setTimeout(() => {
				this.props.socketLoadStartChallenge();
				this.props.timer.start();
			}, 600)
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);

		this.props.socketLoadCloseConnection();
		this.props.checkGameReady(false);

		clearInterval(this.interval);
	}

	render() {
		// if(!this.state.windowHeight) {
		// 	return null;
		// }
		return (
		  <SwipeItComponent
			{...this.state}
			returnTimeLeft={this.returnTimeLeft}
			updateCard={this.updateCard}
			handleControl={this.handleControl}
			validateCard={this.validateCard}
			uiRedirect={this.props.uiRedirect}
			imagesLoaded={this.state.imagesLoaded}
			handlePreloadImages={this.handlePreloadImages}
			lang={this.props.appGetInterfaceLang}
			handleHint={this.props.handleHint}
			handleRunHint={this.handleRunHint}
			userInfo={this.props.userInfo}
		  />
		);
	}
}

const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
  <Timer {...timerProps}>
	  {timerRenderProps =>
		<WrappedComponent {...wrappedComponentProps} timer={timerRenderProps} />}
  </Timer>
);

const TimerHOC = withTimer({
	direction: 'backward',
	initialTime: 60000,
	startImmediately: false,
})(SwipeItContainer);

export default TimerHOC;