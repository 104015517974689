import React, {useState} from 'react';
import clsx from "clsx";
import { useGesture } from 'react-use-gesture';
import { useSpring, animated } from 'react-spring';
import clamp from 'lodash-es/clamp';
import { motion, AnimatePresence } from "framer-motion";
import Preload from "react-preload";
import logger from 'AppUtils/logging';

import WrapperGame from '../../../subcomponents/wrapper-game/WrapperGame';
import TimerComponent from "../../../../../components/timer/Timer";
import InstructionsGame from "../../../subcomponents/instructions-game/InstructionsGame";
import ProgressBar from "../../../../../components/progress-bar/ProgressBar";
import LoaderPage from "../../../../../components/loader-page/LoaderPage";

import './ConnectWords.scss';

import bgWord from './img/bg-word.png';
import bgWordActive from './img/bg-word-active.png';
import correct from '../../../img/correct.svg';
import incorrect from '../../../img/incorrect.svg';

const gameVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.2,
		}
	}
}

const wordVariants = {
	hidden: {
		scale: 0
	},
	visible: (custom) => ({
		scale: 1,
		transition: {
			type: 'spring',
			stiffness: 300,
			damping: 20,
			duration: 0.2,
			delay: custom * 0.2
		}
	})
}

const validIconVariant = {
	hidden: {
		top: '50%',
		left: 0,
		right: 0,
		opacity: 0
	},
	visible: {
		top: '30%',
		opacity: [1, 0],
		transition: {
			type: 'tween',
			duration: 1,
			times: [0, 0.9]
		},
	},
}

const ConnectWordsComponent = (props) => {

	let progress = 0;

	if(props.challengePlay && props.imagesLoaded) {
		progress = (((props.challengePlay.current_round+1)/props.answers.length) * 100).toFixed(3);

		if(props.challengePlay.current_round + 1 === props.challengePlay.total_rounds) {
			progress = 100;
		}
	}

	const [currentDragging, setCurrentDragging] = useState(null);
	const [currentOverlap, setCurrentOverlap] = useState(null);
	const [initialCoords, setInitialCoords] = useState([{x: 0, y: 0}, {x: 0, y: 0}, {x: 0, y: 0}, {x: 0, y: 0}]);
	const [{ xy1 }, set1] = useSpring(() => ({ xy1: [0, 0] }))
	const [{ xy2 }, set2] = useSpring(() => ({ xy2: [0, 0] }))
	const [{ xy3 }, set3] = useSpring(() => ({ xy3: [0, 0] }))
	const [{ xy4 }, set4] = useSpring(() => ({ xy4: [0, 0] }))
	const [lastValidatedCoords, setLastValidatedCoords] = useState({x: 0, y: 0});

	let rotateWord1Val = 0;
	let rotateWord2Val = 0;
	let rotateWord3Val = 0;
	let rotateWord4Val = 0;

	let rotateWordsVal = [[0, 18, 200, 150], [18, 221, 27, 183], [0, 18, 200, 150]];
	logger.info('wordsTemplate', props.wordsTemplate)

	const [{ rotateWord1 }, setRotateWord1] = useSpring(() => ({ rotateWord1: rotateWordsVal[props.wordsTemplate][0] }))
	const [{ rotateWord2 }, setRotateWord2] = useSpring(() => ({ rotateWord2: rotateWordsVal[props.wordsTemplate][1] }))
	const [{ rotateWord3 }, setRotateWord3] = useSpring(() => ({ rotateWord3: rotateWordsVal[props.wordsTemplate][2] }))
	const [{ rotateWord4 }, setRotateWord4] = useSpring(() => ({ rotateWord4: rotateWordsVal[props.wordsTemplate][3] }))

	const [{ rotateWordWrapper1 }, setRotateWordWrapper1] = useSpring(() => ({ rotateWordWrapper1: rotateWordsVal[props.wordsTemplate][0] }))
	const [{ rotateWordWrapper2 }, setRotateWordWrapper2] = useSpring(() => ({ rotateWordWrapper2: rotateWordsVal[props.wordsTemplate][0] }))
	const [{ rotateWordWrapper3 }, setRotateWordWrapper3] = useSpring(() => ({ rotateWordWrapper3: rotateWordsVal[props.wordsTemplate][0] }))
	const [{ rotateWordWrapper4 }, setRotateWordWrapper4] = useSpring(() => ({ rotateWordWrapper4: rotateWordsVal[props.wordsTemplate][0] }))

	const word1Ref = React.useRef(null)
	const word2Ref = React.useRef(null)
	const word3Ref = React.useRef(null)
	const word4Ref = React.useRef(null)

	let currentCircle = false;
	let currentCircleWithBounding = false;

	const nextWords = () => {
		props.setValidating(true);
		const newTemplate = props.getRandomInt(0, 2);
		if(props.challengePlay.current_round + 1 === props.challengePlay.total_rounds) {
			// setTimeout(() => {
			// 	props.uiRedirect({
			// 		pathname: '/result-game'
			// 	})
			// }, 1500)
		} else {
			setTimeout(() => {
				set1({ xy1: [0, 0] })
				set2({ xy2: [0, 0] })
				set3({ xy3: [0, 0] })
				set4({ xy4: [0, 0] })
				logger.info('magic number timeout', newTemplate, props.wordsTemplate)
				logger.info(rotateWordsVal[props.wordsTemplate], rotateWordsVal)

				setCurrentDragging(null);
				setCurrentOverlap(null);
				setRotateWord1({rotateWord1: rotateWordsVal[newTemplate][0]})
				setRotateWord2({rotateWord2: rotateWordsVal[newTemplate][1]})
				setRotateWord3({rotateWord3: rotateWordsVal[newTemplate][2]})
				setRotateWord4({rotateWord4: rotateWordsVal[newTemplate][3]})

				// props.handleChoice();
				props.handleNext(newTemplate);
			}, 800)
		}
	}

	const setCurrentCircle = (word, index) => {
		currentCircle = word.current.getBoundingClientRect();

		if(currentCircle) {
			currentCircleWithBounding = {
				left: currentCircle.left,
				right: currentCircle.right,
				top: props.circlePosition[index] === 'bottom' ? currentCircle.top - currentCircle.height : currentCircle.top,
				bottom: props.circlePosition[index] === 'top' ? currentCircle.bottom + currentCircle.height : currentCircle.bottom,
			}
		}
	}

	const checkOverlapForEach = (wordStart, index) => {
		const circlePosArr = [word1Ref.current.getBoundingClientRect(), word2Ref.current.getBoundingClientRect(), word3Ref.current.getBoundingClientRect(), word4Ref.current.getBoundingClientRect()];
		let circlePosArrWithBounding = [];
		for(let i = 0; i < 4; i++) {
			circlePosArrWithBounding[i] = {
				left: circlePosArr[i].left,
				right: circlePosArr[i].right,
				top: props.circlePosition[i] === 'bottom' ? circlePosArr[i].top - circlePosArr[i].height : circlePosArr[i].top,
				bottom: props.circlePosition[i] === 'top' ? circlePosArr[i].bottom + circlePosArr[i].height : circlePosArr[i].bottom,
			}
		}

		const wordStartArea = word1Ref.current.getBoundingClientRect().height*2 * word1Ref.current.getBoundingClientRect().width;

		for(let i = 0; i < 4; i++) {

			if(i !== index) {
				let intersectAreaPercentage = ((calculateIntersect(wordStart, circlePosArrWithBounding[i])/wordStartArea) * 100).toFixed(3);

				logger.info(intersectAreaPercentage);

				if(intersectAreaPercentage > 50) {
					setCurrentOverlap(i)
					return [true, circlePosArr[i], i];
				}

				setCurrentOverlap(null)
			}
		}
	}

	const calculateIntersect = (rect1, rect2) => {
		let x_overlap = Math.max(0, Math.min(rect1.right, rect2.right) - Math.max(rect1.left, rect2.left));
		let y_overlap = Math.max(0, Math.min(rect1.bottom, rect2.bottom) - Math.max(rect1.top, rect2.top));
		let overlapArea = x_overlap * y_overlap;

		return overlapArea;
	}

	const setRotateWordByIndex = (index, flip) => {
		let value = 0;
		let valueElse = 180;
		if(flip) {
			value = 180;
			valueElse = 0;
		}
		let valueTo = props.circlePosition[index] === 'top' ? value : valueElse;
		switch(index) {
			case 0:
				setRotateWord1({ rotateWord1: props.circlePosition[index] === 'top' ? value : valueElse })
				// if(flip) {
				// 	setRotateWordWrapper1({ rotateWordWrapper1: props.circlePosition[index] === 'top' ? value : valueElse })
				// }
				break;
			case 1:
				setRotateWord2({ rotateWord2: props.circlePosition[index] === 'top' ? value : valueElse })
				break;
			case 2:
				setRotateWord3({ rotateWord3: props.circlePosition[index] === 'top' ? value : valueElse })
				break;
			case 3:
				setRotateWord4({ rotateWord4: props.circlePosition[index] === 'top' ? value : valueElse })
				break;
		}

	}

	const handleDragStart = (word, index) => {
		if(!props.validating && props.animationComplete >= 4) {
			props.onDragstartHandler();
			setCurrentDragging(index);
			let copyInitialCoords = initialCoords;
			copyInitialCoords[index] = {x: word.current.getBoundingClientRect().x, y: word.current.getBoundingClientRect().y};
			setInitialCoords(copyInitialCoords)
		}
	}

	const handleDrag = (word, index, delta, down) => {
		if(!props.validating && props.animationComplete >= 4) {
			switch(index) {
				case 0:
					set1({ xy1: delta, immediate: down });
					break;
				case 1:
					set2({ xy2: delta, immediate: down });
					break;
				case 2:
					set3({ xy3: delta, immediate: down });
					break;
				case 3:
					set4({ xy4: delta, immediate: down });
					break;
			}

			setCurrentCircle(word, index)
			checkOverlapForEach(currentCircleWithBounding, index)
		}
	}

	const handleDragEnd = (word, index, velocity) => {
		if(!props.validating && props.animationComplete >= 4) {
			props.onDragendHandler();
			velocity = clamp(velocity, 1, 8)
			setCurrentCircle(word, index)
			if(checkOverlapForEach(currentCircleWithBounding, index) && checkOverlapForEach(currentCircleWithBounding, index)[0]) {
				let ovelapingWord = checkOverlapForEach(currentCircleWithBounding, index)[1];
				let overlapingWordIndex = checkOverlapForEach(currentCircleWithBounding, index)[2];

				switch(index) {
					case 0:
						set1({ xy1: [ovelapingWord.x - initialCoords[index].x, ovelapingWord.y - initialCoords[index].y - (props.circlePosition[index] === 'top' ? currentCircle.height : - currentCircle.height)]})
						break;
					case 1:
						set2({ xy2: [ovelapingWord.x - initialCoords[index].x, ovelapingWord.y - initialCoords[index].y - (props.circlePosition[index] === 'top' ? currentCircle.height : - currentCircle.height)]})
						break;
					case 2:
						set3({ xy3: [ovelapingWord.x - initialCoords[index].x, ovelapingWord.y - initialCoords[index].y - (props.circlePosition[index] === 'top' ? currentCircle.height : - currentCircle.height)]})
						break;
					case 3:
						set4({ xy4: [ovelapingWord.x - initialCoords[index].x, ovelapingWord.y - initialCoords[index].y - (props.circlePosition[index] === 'top' ? currentCircle.height : - currentCircle.height)]})
						break;
				}

				logger.info(word, 'word')
				setLastValidatedCoords({ x: word.current.offsetLeft, y: word.current.offsetTop })

				setRotateWordByIndex(index);

				let samePosition = false;
				if(props.circlePosition[index] === props.circlePosition[overlapingWordIndex]) {
					samePosition = true;
				}

				setRotateWordByIndex(overlapingWordIndex, samePosition);
				props.handleChoice(index, overlapingWordIndex);
				nextWords();
			} else {
				setCurrentDragging(null);
				switch(index) {
					case 0:
						set1({ xy1: [0, 0], config: { mass: 1, tension: 2000 * velocity, friction: 50 * velocity } })
						break;
					case 1:
						set2({ xy2: [0, 0], config: { mass: 1, tension: 2000 * velocity, friction: 50 * velocity } })
						break;
					case 2:
						set3({ xy3: [0, 0], config: { mass: 1, tension: 2000 * velocity, friction: 50 * velocity } })
						break;
					case 3:
						set4({ xy4: [0, 0], config: { mass: 1, tension: 2000 * velocity, friction: 50 * velocity } })
						break;
				}
			}
		}
	}

	const bindWord1 = useGesture({
		onDragStart: () => {
			handleDragStart(word1Ref, 0)
		},
		onDrag: ({ down, delta, velocity }) => {
			handleDrag(word1Ref, 0, delta, down);
		},
		onDragEnd: ({ down, delta, velocity }) => {
			handleDragEnd(word1Ref, 0, velocity);
		}
	})
	const bindWord2 = useGesture({
		onDragStart: () => {
			handleDragStart(word2Ref, 1)
		},
		onDrag: ({ down, delta, velocity }) => {
			handleDrag(word2Ref, 1, delta, down);
		},
		onDragEnd: ({ down, delta, velocity }) => {
			handleDragEnd(word2Ref, 1, velocity);
		}
	})
	const bindWord3 = useGesture({
		onDragStart: () => {
			handleDragStart(word3Ref, 2)
		},
		onDrag: ({ down, delta, velocity }) => {
			handleDrag(word3Ref, 2, delta, down);
		},
		onDragEnd: ({ down, delta, velocity }) => {
			handleDragEnd(word3Ref, 2, velocity);
		}
	})
	const bindWord4 = useGesture({
		onDragStart: () => {
			handleDragStart(word4Ref, 3)
		},
		onDrag: ({ down, delta, velocity }) => {
			handleDrag(word4Ref, 3, delta, down);
		},
		onDragEnd: ({ down, delta, velocity }) => {
			handleDragEnd(word4Ref, 3, velocity);
		}
	})

	//handle hint
	if(props.runHint && props.availableSkills > 0 && props.challengePlay) {
		props.handleChoice(null, null, true);
		nextWords();
	}

	return (
	  <>
		  <LoaderPage isVisible={props.imagesLoaded && props.challengePlay} />
		  <Preload
			images={[bgWord, bgWordActive, correct, incorrect]}
			autoResolveDelay={5000}
			// onError={}
			onSuccess={props.handlePreloadImages}
			resolveOnError={true}
			mountChildren={false}
		  >
		  </Preload>

		  {props.imagesLoaded && props.challengePlay && (
			<motion.div
			  className="connect-words-component content-wrapper"
			  variants={gameVariants}
			  initial="hidden"
			  animate="visible"
			>
				<WrapperGame>
					<TimerComponent timeLeft={props.returnTimeLeft} timeCritical={props.timeCritical} />

					<AnimatePresence key={props.current}>
						<ul className={clsx("words-list", `words-template-${props.wordsTemplate + 1}`, props.choice !== null && `case-${props.choice[0]}-${props.choice[1]}`, props.animationComplete >= 4 && 'can-click')} ref={props.constraintsRef}>
							<animated.li
							  ref={word1Ref}
							  className={clsx('item', currentDragging === 0 && 'dragging', currentOverlap === 0 && 'overlapping')}
							  {...bindWord1()}
							  data-match={1}
							  style={{ transform: xy1.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`) }}
							>
								<motion.div
								  custom={1}
								  variants={wordVariants}
								  initial="hidden"
								  animate="visible"
								  exit="hidden"
								  onAnimationComplete={props.handleAnimationComplete}
								>
									<animated.div
									  className="words-wrapper"
									  style={{ transform: rotateWord1.interpolate(rotate => `rotate(${rotate}deg)`) }}
									>
										<img src={currentDragging === 0 || currentOverlap === 0 ? bgWordActive : bgWord} alt="" className="bg-word"/>
										<div className="words-inner-wrapper">
											<p>{props.challengePlay.rounds.phrases[0].text[props.lang]}</p>
										</div>
									</animated.div>
								</motion.div>
							</animated.li>
							<animated.li
							  ref={word2Ref}
							  data-match={2}
							  className={clsx('item', currentDragging === 1 && 'dragging', currentOverlap === 1 && 'overlapping')}
							  {...bindWord2()}
							  style={{ transform: xy2.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`) }}
							>
								<motion.div
								  custom={2}
								  variants={wordVariants}
								  initial="hidden"
								  animate="visible"
								  exit="hidden"
								  onAnimationComplete={props.handleAnimationComplete}
								>
									<animated.div
									  className="words-wrapper"
									  style={{ transform: rotateWord2.interpolate(rotate => `rotate(${rotate}deg)`) }}
									>
										<img src={currentDragging === 1 || currentOverlap === 1 ? bgWordActive : bgWord} alt="" className="bg-word"/>
										<div className="words-inner-wrapper">
											<p>{props.challengePlay.rounds.phrases[1].text[props.lang]}</p>
										</div>
									</animated.div>
								</motion.div>
							</animated.li>
							<animated.li
							  ref={word3Ref}
							  data-match={2}
							  className={clsx('item', currentDragging === 2 && 'dragging', currentOverlap === 2 && 'overlapping')}
							  {...bindWord3()}
							  style={{ transform: xy3.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`) }}
							>
								<motion.div
								  custom={3}
								  variants={wordVariants}
								  initial="hidden"
								  animate="visible"
								  exit="hidden"
								  onAnimationComplete={props.handleAnimationComplete}
								>
									<animated.div
									  className="words-wrapper"
									  style={{ transform: rotateWord3.interpolate(rotate => `rotate(${rotate}deg)`) }}
									>
										<img src={currentDragging === 2 || currentOverlap === 2 ? bgWordActive : bgWord} alt="" className="bg-word"/>
										<div className="words-inner-wrapper">
											<p>{props.challengePlay.rounds.phrases[2].text[props.lang]}</p>
										</div>
									</animated.div>
								</motion.div>
							</animated.li>
							<animated.li
							  ref={word4Ref}
							  data-match={1}
							  className={clsx('item', currentDragging === 3 && 'dragging', currentOverlap === 3 && 'overlapping')}
							  {...bindWord4()}
							  style={{ transform: xy4.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`) }}
							>
								<motion.div
								  custom={4}
								  variants={wordVariants}
								  initial="hidden"
								  animate="visible"
								  exit="hidden"
								  onAnimationComplete={props.handleAnimationComplete}
								>
									<animated.div
									  className="words-wrapper"
									  style={{ transform: rotateWord4.interpolate(rotate => `rotate(${rotate}deg)`) }}
									>
										<img src={currentDragging === 3 || currentOverlap === 3 ? bgWordActive : bgWord} alt="" className="bg-word"/>
										<div className="words-inner-wrapper">
											<p>{props.challengePlay.rounds.phrases[3].text[props.lang]}</p>
										</div>
									</animated.div>
								</motion.div>
							</animated.li>
						</ul>
					</AnimatePresence>

					{ props.answers[props.current] && props.answers[props.current] !== null && props.validating ?
					  <motion.img
						className="incorect-icon"
						src={correct}
						style={{height: 90, width: 90, position: "absolute", marginLeft: 'auto', marginRight: 'auto', zIndex: 2}}
						variants={validIconVariant}
						initial="hidden"
						animate="visible"
					  />
					  : !props.answers[props.current] && props.answers[props.current] !== null && props.validating ?
						<motion.img
						  className="correct-icon"
						  src={incorrect}
						  style={{height: 90, width: 90, position: "absolute", marginLeft: 'auto', marginRight: 'auto', zIndex: 2}}
						  variants={validIconVariant}
						  initial="hidden"
						  animate="visible"
						/>
						: null
					}

					<ProgressBar steps={[...props.answers]} progress={progress} total={props.challengePlay.current_round+1} />
				</WrapperGame>
				<InstructionsGame
				  lineFirst={props.challengePlay.hint[props.lang]}
				/>
			</motion.div>
		  )}
	  </>
	);
};

export default ConnectWordsComponent;