import React from 'react';
import { HtmlParser } from 'AppUtils/language';

import './InstructionsGame.scss';

const InstructionsGame = (props) => {
	return (
	  <div className="instructions-game">
		  <p className="info-icon">i</p>
		  <div className="instructions">
			  {props.lineFirst ? <p><HtmlParser html={props.lineFirst} /></p> : null }
		  </div>
	  </div>
	);
};

export default InstructionsGame;