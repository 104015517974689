import React from 'react';
import { motion } from "framer-motion";
import Preload from "react-preload";
import { getValue } from 'AppUtils/objects';
import getText, { HtmlParser } from 'AppUtils/language';

import iconSkills from './img/icon-skills.svg';
import arrowRight from './img/arrow-right.svg';
import skillsBtn from './img/bg-button-orange.png';

import LoaderPage from "../../components/loader-page/LoaderPage";
import Button from "../../components/button/Button";
import BoxShadow from "../../components/box-shadow/BoxShadow";

import './Skills.scss';

const skillsVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.2,
        }
    }
}

const descriptionVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.4,
            delay: 0.2
        }
    }
}

const listVariants = {
    hidden: {
        y: -40,
        opacity: 0,
    },
    visible: (custom) => ({
        y: 0,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.3,
            delay: custom * 0.1
        }
    })
}

const SkillsComponent = (props) => {

	const userInfo  = getValue(props, 'userInfo.character');
	const skillsPoints = getValue(userInfo, 'skills.points.available', 0);
	const lang = props.appGetInterfaceLang;

	let swipeIt = {};
	let swipeItInvested = 0;
	let lineUp = {};
	let lineUpInvested = 0;
	let top3 = {};
	let top3Invested = 0;
	let matchIt = {};
	let matchItInvested = 0;


	if (props.repositorySkills.length > 0) {
		swipeIt = props.repositorySkills.find(item => item.code == 'swipeIt');
		swipeItInvested = getValue(userInfo, `skills.items.swipeIt.invested`, 0);

		lineUp = props.repositorySkills.find(item => item.code == 'lineUp');
		lineUpInvested = getValue(userInfo, `skills.items.lineUp.invested`, 0);

		top3 = props.repositorySkills.find(item => item.code == 'top3');
		top3Invested = getValue(userInfo, `skills.items.top3.invested`, 0);

		matchIt = props.repositorySkills.find(item => item.code == 'matchIt');
		matchItInvested = getValue(userInfo, `skills.items.matchIt.invested`, 0);
    }


    return (
        <>
            <LoaderPage isVisible={props.imagesLoaded && props.repositorySkills.length > 0} />
            <Preload
              images={[iconSkills, arrowRight, skillsBtn]}
              autoResolveDelay={5000}
              // onError={}
              onSuccess={props.handlePreloadImages}
              resolveOnError={true}
              mountChildren={false}
            >
            </Preload>

            {props.imagesLoaded && props.repositorySkills.length > 0 && (
              <motion.div
                className="skills-component"
                variants={skillsVariants}
                initial="hidden"
                animate="visible"
              >
                  <div className="content-wrapper-small">
                      <motion.div
                        className="points-available"
                        variants={descriptionVariants}
                        initial="hidden"
                        animate="visible"
                      >
                          <img src={iconSkills} alt=""/>
                          <span className="points-no">{skillsPoints}</span>
                          <p><strong>{getText('Skill Points')}</strong> <br/>
							  {getText('Available')}</p>
                      </motion.div>

                      <motion.div
                        custom={2}
                        variants={listVariants}
                        initial="hidden"
                        animate="visible"
                      >
                          <BoxShadow className="box-skill">
                              <h3>{getValue(swipeIt, `name.${lang}`)}</h3>
                              <div className="levels">
                                  <div className="skill-level current">
                                      <p>{getText('Current level')}</p>
                                      <div className="box-description">
                                          <p><HtmlParser html={getValue(swipeIt, `currentLevelText.${lang}`)} strFormat={[`<strong>${swipeItInvested} ${swipeItInvested > 1 || swipeItInvested === 0 ? getValue(swipeIt, `unit.plural.${lang}`) : getValue(swipeIt, `unit.singular.${lang}`)} </strong>`]} local={true} /></p>
                                      </div>
                                  </div>
                                  <img src={arrowRight} alt=""/>
                                  <div className="skill-level next">
                                      <p>{getText('Next level')}</p>
                                      <div className="box-description">
                                          <p><HtmlParser html={getValue(swipeIt, `nextLevelText.${lang}`)} strFormat={[`<strong>${swipeItInvested + 1} ${swipeItInvested + 1 > 1 ? getValue(swipeIt, `unit.plural.${lang}`) : getValue(swipeIt, `unit.singular.${lang}`)} </strong>`]} local={true} /></p>
                                      </div>
                                  </div>
                              </div>
                              <Button onClick={() => props.levelUp('swipeIt', 1)} title={getText('Level UP')} slim={true}/>
                          </BoxShadow>
                      </motion.div>

                      <motion.div
                        custom={3}
                        variants={listVariants}
                        initial="hidden"
                        animate="visible"
                      >
                          <BoxShadow className="box-skill">
                              <h3>{getValue(lineUp, `name.${lang}`)}</h3>
                              <div className="levels">
                                  <div className="skill-level current">
                                      <p>{getText('Current level')}</p>
                                      <div className="box-description">
                                          <p><HtmlParser html={getValue(lineUp, `currentLevelText.${lang}`)} strFormat={[`<strong>${lineUpInvested} ${lineUpInvested > 1 || lineUpInvested === 0 ? getValue(lineUp, `unit.plural.${lang}`) : getValue(lineUp, `unit.singular.${lang}`)} </strong>`]} local={true} /></p>
                                      </div>
                                  </div>
                                  <img src={arrowRight} alt=""/>
                                  <div className="skill-level next">
                                      <p>{getText('Next level')}</p>
                                      <div className="box-description">
                                          <p><HtmlParser html={getValue(lineUp, `nextLevelText.${lang}`)} strFormat={[`<strong>${lineUpInvested + 1} ${lineUpInvested + 1 > 1 ? getValue(lineUp, `unit.plural.${lang}`) : getValue(lineUp, `unit.singular.${lang}`)} </strong>`]} local={true} /></p>
                                      </div>
                                  </div>
                              </div>
                              <Button onClick={() => props.levelUp('lineUp', 1)} title={getText('Level UP')} slim={true}/>
                          </BoxShadow>
                      </motion.div>

                      <motion.div
                        custom={4}
                        variants={listVariants}
                        initial="hidden"
                        animate="visible"
                      >
                          <BoxShadow className="box-skill">
                              <h3>{getValue(top3, `name.${lang}`)}</h3>
                              <div className="levels">
                                  <div className="skill-level current">
                                      <p>{getText('Current level')}</p>
                                      <div className="box-description">
                                          <p><HtmlParser html={getValue(top3, `currentLevelText.${lang}`)} strFormat={[`<strong>${top3Invested} ${top3Invested > 1 || top3Invested === 0 ? getValue(top3, `unit.plural.${lang}`) : getValue(top3, `unit.singular.${lang}`)} </strong>`]} local={true} /></p>
                                      </div>
                                  </div>
                                  <img src={arrowRight} alt=""/>
                                  <div className="skill-level next">
                                      <p>{getText('Next level')}</p>
                                      <div className="box-description">
                                          <p><HtmlParser html={getValue(top3, `nextLevelText.${lang}`)} strFormat={[`<strong>${top3Invested + 1} ${top3Invested + 1 > 1 ? getValue(top3, `unit.plural.${lang}`) : getValue(top3, `unit.singular.${lang}`)} </strong>`]} local={true} /></p>
                                      </div>
                                  </div>
                              </div>
                              <Button onClick={() => props.levelUp('top3', 1)} title={getText('Level UP')} slim={true}/>
                          </BoxShadow>
                      </motion.div>

                      <motion.div
                        custom={4}
                        variants={listVariants}
                        initial="hidden"
                        animate="visible"
                      >
                          <BoxShadow className="box-skill">
                              <h3>{getValue(matchIt, `name.${lang}`)}</h3>
                              <div className="levels">
                                  <div className="skill-level current">
                                      <p>{getText('Current level')}</p>
                                      <div className="box-description">
                                          <p><HtmlParser html={getValue(matchIt, `currentLevelText.${lang}`)} strFormat={[`<strong>${matchItInvested} ${matchItInvested > 1 || matchItInvested === 0 ? getValue(matchIt, `unit.plural.${lang}`) : getValue(matchIt, `unit.singular.${lang}`)} </strong>`]} local={true} /></p>
                                      </div>
                                  </div>
                                  <img src={arrowRight} alt=""/>
                                  <div className="skill-level next">
                                      <p>{getText('Next level')}</p>
                                      <div className="box-description">
                                          <p><HtmlParser html={getValue(matchIt, `nextLevelText.${lang}`)} strFormat={[`<strong>${matchItInvested + 1} ${matchItInvested + 1 > 1 ? getValue(matchIt, `unit.plural.${lang}`) : getValue(matchIt, `unit.singular.${lang}`)} </strong>`]} local={true} /></p>
                                      </div>
                                  </div>
                              </div>
                              <Button onClick={() => props.levelUp('matchIt', 1)} title={getText('Level UP')} slim={true}/>
                          </BoxShadow>
                      </motion.div>
                  </div>
              </motion.div>
            )}
        </>
    );
};

export default SkillsComponent;