import React, {useState} from 'react';
import clsx from "clsx";
import { useGesture } from 'react-use-gesture';
import { useSprings, animated } from 'react-spring';
import logger from 'AppUtils/logging';

import BoxShadow from "../../../../../../components/box-shadow/BoxShadow";
import BoxWave from "../../../../../../components/box-wave/BoxWave";

const QuizArea = (props) => {
	const [springs, setSprings] = useSprings(props.dataSet.answers.length, index => ({ dragPosX: 0 }));

	const answerRef = React.useRef(null);

	const handleDragStart = (index) => {

	}

	const handleDrag = (index, delta, down) => {
		logger.info(springs)
		setSprings(springIndex => {
			if(springIndex === index) {
				return {
					dragPosX: delta[0], immediate: true
				}
			}
		})

		if(delta[0] > answerRef.current.clientWidth) {
			setSprings(index => ({dragPosX: 0, immediate: true}))
			if(!props.dataSet.touched) {
				props.handleClick(index)
			}
		}
	}

	const handleDragEnd = (index, delta, down) => {
		if(delta[0] < answerRef.current.clientWidth) {
			setSprings(index => ({dragPosX: 0, immediate: down}))
		}
	}

	const bindDrag = useGesture({
		onDragStart: () => {
			logger.info('drag')
		},
		onDrag: ({ args: [originalIndex], down, delta, velocity }) => {
			logger.info(originalIndex, 'originalIndex')
			handleDrag(originalIndex, delta, down);
		},
		onDragEnd: ({ args: [originalIndex], down, delta, velocity }) => {
			handleDragEnd(originalIndex, delta, down);
		}
	})

	const renderAnswers = () => {
		return springs.map(({dragPosX}, i) => {
			return (
			  <div
				className={clsx({
					answer: true,
					selected: i === props.answerChoice,
					correct: i === props.answerChoice && props.dataSet.touched && i === props.dataSet.correct,
					incorrect: i === props.answerChoice && props.dataSet.touched && i !== props.dataSet.correct
				})}
				key={i}
			  >
				  <button
					ref={answerRef}
					// onClick={(e) => {
					//   !props.dataSet.touched ?
					// 	props.handleClick(i)
					// 	:
					// 	e.preventDefault()
				  // }}
					  >
					  {i === props.answerChoice ?
						<>
							<p>{props.dataSet.answers[i]}</p>
							<BoxWave isBig={true}><span>{props.dataSet.answers[i]}</span></BoxWave>
						</>
						:
						<>
							<p>{props.dataSet.answers[i]}</p>
							<BoxShadow><span>{props.dataSet.answers[i]}</span></BoxShadow>
						</>
					  }
					  <animated.div
						className="drag-handle"
						{...bindDrag(i)}
						style={{ transform: dragPosX.interpolate(dragPosX => `translate3d(${dragPosX}px,0px,0)`) }}
					  ></animated.div>
					  <animated.div
						className="bg-pattern"
						style={{ width: dragPosX.interpolate(dragPosX => `${dragPosX}px`) }}
					  ></animated.div>
				  </button>
			  </div>
			)
		})
	};

	return (
	  <div className="quiz-area">
		  <h3 className="question">{props.dataSet.question}</h3>
		  <div className="answer-list">
			  {renderAnswers()}
		  </div>
	  </div>
	);
};

export default QuizArea;