import { createSelector } from 'reselect';

const userMsg = state => state.user.msg;
const userStatusCode = state => state.user.statusCode;
const userInfo = state => state.user.info;
const userUpdateInfo = state => state.user.updateInfo;
const userPoints = state => state.user.points;
const userCredits = state => state.user.credits;
const userBuyCredits = state => state.user.buyCredits;
const userLeaderBoard = state => state.user.leaderBoard;
const userEvent = state => state.user.event;
const userGuestEvent = state => state.user.guestEvent;
const userUnsubscribe = state => state.user.unsubscribe;
const userGenerate = state => state.user.generate;
const userSubscribeDirect = state => state.user.subscribeDirect;
const userRepositoryAchievements = state => state.user.repositoryAchievements;
const userRepositorySkills = state => state.user.repositorySkills;
const userRepositoryBanners = state => state.user.repositoryBanners;
const userRepositoryCreditPackages = state => state.user.repositoryCreditPackages;
const userRepositoryPrizes = state => state.user.repositoryPrizes;
const userRepositoryMap = state => state.user.repositoryMap;
const userRepositoryAvatars = state => state.user.repositoryAvatars;
const userRepositorySteps = state => state.user.repositorySteps;
const userNotifications = state => state.user.notifications;
const userNotificationsConsume = state => state.user.notificationsConsume;
const userProfileUpdate = state => state.user.profileUpdate;

export {
	userMsg,
	userStatusCode,
	userInfo,
	userUpdateInfo,
	userPoints,
	userCredits,
	userBuyCredits,
	userLeaderBoard,
	userEvent,
	userGuestEvent,
	userUnsubscribe,
	userGenerate,
	userSubscribeDirect,
	userRepositoryAchievements,
	userRepositorySkills,
	userRepositoryBanners,
	userRepositoryCreditPackages,
	userRepositoryPrizes,
	userRepositoryMap,
	userRepositoryAvatars,
	userRepositorySteps,
	userNotifications,
	userNotificationsConsume,
	userProfileUpdate,
};
