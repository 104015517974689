import React from 'react';
import {Link} from "react-router-dom";
import { motion } from "framer-motion";
import Preload from "react-preload";
import { getValue } from 'AppUtils/objects';
import moment from 'moment';
import XDate from 'AppUtils/xdate';
import getText from 'AppUtils/language';

import LoaderPage from "../../../../components/loader-page/LoaderPage";
import BoxShadow from "../../../../components/box-shadow/BoxShadow";

import fbShare from './img/fb-share.svg';

import './AchievementsDetails.scss';

const achievementsDetailsVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.2,
        }
    }
}

const AchievementsDetailsComponent = (props) => {
	// created_at: "2020-09-21T10:29:33.296000Z"
	// description: {en: "First task completed with 100% accuracy", fr: "First task completed with 100% accuracy"}
	// image: "https://picsum.photos/200"
	// name: {en: "Head start", fr: "Head start"}
	// updated_at: "2020-09-21T10:29:33.296000Z"
	// id: "5f68808d99a27171ec3114b2"
    // unlockDate: "2020-09-17 02:59:59"

    return (
        <>
            <LoaderPage isVisible={props.imagesLoaded && props.achievement} />
            <Preload
              images={[fbShare]}
              autoResolveDelay={5000}
              // onError={}
              onSuccess={props.handlePreloadImages}
              resolveOnError={true}
              mountChildren={false}
            >
            </Preload>

            {props.imagesLoaded && props.achievement && (
              <motion.div
                className="achievements-details-component"
                variants={achievementsDetailsVariants}
                initial="hidden"
                animate="visible"
              >
                  <div className="content-wrapper-small">
                      <BoxShadow className="achievements-details-box">
                          <img className="icon-achievement" src={props.achievement.image} alt=""/>
                          <p>{props.achievement.description[props.appGetInterfaceLang]}</p>
                          {props.achievement.unlockDate && <p className="date-unlock">{getText('Unlocked on')}: <span>{moment(props.achievement.unlockDate).format('D MMM')}</span></p>}
                          {/*<Link to="#" className="btn-share"><img src={fbShare} alt=""/></Link>*/}
                      </BoxShadow>
                  </div>
              </motion.div>
            )}
        </>
    );
};

export default AchievementsDetailsComponent;