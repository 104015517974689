import React from 'react';
import { motion } from "framer-motion"
import { Link } from "react-router-dom";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import { getValue } from 'AppUtils/objects';
import getText, { HtmlParser } from "AppUtils/language"

import Button from "../../components/button/Button";
import LoaderPage from "../../components/loader-page/LoaderPage";

import globy from './img/globy.svg';
import bgBtnPrize from './img/bg-btn-prize.svg';
import bgBtnStore from './img/bg-btn-store.svg';
import prizeBmw from './img/prize-bmw.png';
import prizeTickets from './img/prize-tickets.png';
import triangle from './img/triangle.svg';

import './Home.scss';
import Onboarding from "../user/onboarding/OnboardingContainer";

const buttonVariants = {
	hidden: {
		y: '100vh'
	},
	visible: {
		y: 0,
		transition: {
			type: 'tween',
			duration: 0.6
		}
	}
}

const earthVariants = {
	hidden: {
		x: '100vw'
	},
	visible: {
		x: 0,
		transition: {
			type: 'tween',
			duration: 0.6
		}
	}
}

const buttonLeftVariants = {
	hidden: {
		x: '-100vw',
		y: 400
	},
	visible: {
		x: 0,
		y: 0,
		transition: {
			type: 'tween',
			duration: 0.6
		}
	}
}

const buttonRightVariants = {
	hidden: {
		x: '100vw',
		y: 400
	},
	visible: {
		x: 0,
		y: 0,
		transition: {
			type: 'tween',
			duration: 0.6
		}
	}
}

const HomeComponent = (props) => {
	const userInfoCh = getValue(props, 'userInfo.character', '');
	const userInfoEl = getValue(props, 'userInfo.eligibility', '');
	const credits = getValue(userInfoEl, 'credits.purchased', 0) + getValue(userInfoEl, 'credits.subscription', 0);

	return (
		<>
			<LoaderPage isVisible={props.userInfo} />
			{props.userInfo && (
			  <div className="home-component">
				  <div className="tutorial-globe">
					  <motion.div
						className="slider-container"
					  >
							<Onboarding />
					  </motion.div>
					  <motion.div
						className="globy-wrapper"
						variants={earthVariants}
						initial="hidden"
						animate="visible"
					  >
						  <motion.img
							src={globy}
							alt=""
							className="globy"
							style={{
								maxHeight: (props.windowHeight/100) * 30
							}}
						  />
					  </motion.div>
				  </div>
				  <div className="prizes">
					  <motion.div
						className="prize"
						variants={buttonLeftVariants}
						initial="hidden"
						animate="visible"
					  >
						  <Link to="/prizes">
							  <img className="prize-btn-bg" src={bgBtnPrize} alt=""/>
							  <img className="prize-img" src={props.prize?.image} alt=""/>
							  <p>{getText('Grand Prize')}</p>
						  </Link>
					  </motion.div>
					  <motion.div
						className="prize"
						variants={buttonRightVariants}
						initial="hidden"
						animate="visible"
					  >
						  <Link to="/store">
							  <img className="store-btn-bg" src={bgBtnStore} alt=""/>
							  <img className="prize-img" src={prizeTickets} alt=""/>
							  <p>{getText('STORE')}</p>
						  </Link>
					  </motion.div>
				  </div>
				  <motion.div
					variants={buttonVariants}
					initial="hidden"
					animate="visible"
				  >
					  <Button
						title={getText('PLAY')}
						link={`/challenge/instructions/${userInfoCh.map ? userInfoCh.map.activeChallenge : ''}`}
						disabled={false}
						onClick={!userInfoEl.canPlay ? props.handleNotEligible : false}
					  />
				  </motion.div>

				  {userInfoEl.canPlay === false && <div className="warning-message">
					  <img src={triangle} alt=""/>
					  <p><HtmlParser html="You are not eligible to participate. <br/> Check you balance and try again" /></p>
				  </div>}
			  </div>
			)}
		</>
	);
};

export default HomeComponent;