import React from 'react';
import { motion } from "framer-motion";
import Preload from "react-preload";
import getText from 'AppUtils/language';
import clsx from "clsx";

import LoaderPage from "../../components/loader-page/LoaderPage";
import BoxShadow from "../../components/box-shadow/BoxShadow";
import BoxWave from "../../components/box-wave/BoxWave";

import grandPrize from './img/grand-prize.svg';
import monthlyPrize from './img/monthly-prize.svg';
import weeklyPrize from './img/weekly-prize.svg';

import './Prizes.scss';

const prizesVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.2,
        }
    }
}

const listVariants = {
    hidden: {
        y: -40,
        opacity: 0,
    },
    visible: (custom) => ({
        y: 0,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.3,
            delay: custom * 0.1
        }
    })
}

const PrizesComponent = (props) => {
    return (
        <>
            <LoaderPage isVisible={props.imagesLoaded && props.repositoryPrizes.length > 0} />
            <Preload
              images={[grandPrize, monthlyPrize, weeklyPrize]}
              autoResolveDelay={5000}
              // onError={}
              onSuccess={props.handlePreloadImages}
              resolveOnError={true}
              mountChildren={false}
            >
            </Preload>

            {props.imagesLoaded && props.repositoryPrizes.length > 0 && (
              <motion.div
                className="prizes-component"
                variants={prizesVariants}
                initial="hidden"
                animate="visible"
              >
                  <div className="content-wrapper-small">
                      {props.repositoryPrizes.length > 0 && props.repositoryPrizes.map((item, i) => (<motion.div
                        key={item.id}
                        custom={ i + 2}
                        variants={listVariants}
                        initial="hidden"
                        animate="visible"
                      >
                          <BoxShadow className={clsx("prize-box", item.template_id === 1 ? "grand-prize" : item.template_id === 2 ? "monthly-prize" : item.template_id === 3 ? "weekly-prize" : 'monthly-prize' )}>
                              <BoxWave className="prize-title" isBig={true}>
                                  {item.template_id === 1 ?
                                    getText('Grand Prize') :
                                    item.template_id === 2 ?
                                      getText("Monthly prize") :
                                      item.template_id === 3 ?
                                        getText("Weekly prize") :
                                        getText('BEST VALUE')
                                  }
                              </BoxWave>
                              <div className="about-prize">
                                  <div className="prize-img">
                                      <img src={`${item.image}?ts=${window.performance.now()}`} alt=""/>
                                  </div>
                                  <div className="prize-info">
                                      <p>{item.quantity && <span>{item.quantity} x</span>}{item.name[props.lang]}</p>
                                  </div>
                              </div>
                          </BoxShadow>
                      </motion.div>))}
                      {/*<motion.div*/}
                        {/*custom={3}*/}
                        {/*variants={listVariants}*/}
                        {/*initial="hidden"*/}
                        {/*animate="visible"*/}
                      {/*>*/}
                          {/*<BoxShadow className="prize-box monthly-prize">*/}
                              {/*<BoxWave className="prize-title" isBig={true}>BEST VALUE</BoxWave>*/}
                              {/*<div className="about-prize">*/}
                                  {/*<div className="prize-img">*/}
                                      {/*<img src={monthlyPrize} alt=""/>*/}
                                  {/*</div>*/}
                                  {/*<div className="prize-info">*/}
                                      {/*<p><span>3 x</span>2000 €</p>*/}
                                  {/*</div>*/}
                              {/*</div>*/}
                          {/*</BoxShadow>*/}
                      {/*</motion.div>*/}
                      {/*<motion.div*/}
                        {/*custom={4}*/}
                        {/*variants={listVariants}*/}
                        {/*initial="hidden"*/}
                        {/*animate="visible"*/}
                      {/*>*/}
                          {/*<BoxShadow className="prize-box weekly-prize">*/}
                              {/*<BoxWave className="prize-title" isBig={true}>BEST VALUE</BoxWave>*/}
                              {/*<div className="about-prize">*/}
                                  {/*<div className="prize-img">*/}
                                      {/*<img src={weeklyPrize} alt=""/>*/}
                                  {/*</div>*/}
                                  {/*<div className="prize-info">*/}
                                      {/*<p><span>1 x</span>500 €</p>*/}
                                  {/*</div>*/}
                              {/*</div>*/}
                          {/*</BoxShadow>*/}
                      {/*</motion.div>*/}
                  </div>
              </motion.div>
            )}
        </>
    );
};

export default PrizesComponent;