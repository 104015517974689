import React from 'react';

import logoVertical from './img/logo-vertical.png';
import globe from './img/glob.gif';
import logoProvider from './img/logo-provider.png';
import getText from 'AppUtils/language';

import './Loader.scss';

const Loader = () => {
    return (
        <div className="loader">
            <div className="logo-img">
                <img src={logoVertical} alt=""/>
            </div>
            <div className="loading">
                <img src={globe} alt=""/>
                <p>{getText('Loading')}</p>
            </div>
            <div className="banner-logo">
                <img src={logoProvider} alt=""/>
            </div>
        </div>
    );
};

export default Loader;