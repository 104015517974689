import React, {Component} from 'react';

import SignupComponent from "./SignupComponent";

class SignupContainer extends Component {

    state = {
        type: this.props.appGetConfig && this.props.appGetConfig.users ? this.props.appGetConfig.users.subscribeMethod : 'text'
    }

    render() {
        return (
            <SignupComponent
                {...this.props}
                type={this.state.type}
                appGetConfig={this.props.appGetConfig}
                prize={this.props.userRepositoryPrizes?.list.length > 0 ? this.props.userRepositoryPrizes?.list.filter((prize) => prize.position === 1)[0] : false}
            />
        );
    }
}

export default SignupContainer;