import React, {Component} from 'react';

import logger from 'AppUtils/logging';
import RecoverComponent from "./RecoverComponent";

class RecoverContainer extends Component {

	state = {
		type: this.props.appGetConfig && this.props.appGetConfig.users ? this.props.appGetConfig.users.resetPasswordMethod : 'text'
	}

    render() {
        return (
            <RecoverComponent
			  {...this.props}
			  type={this.state.type}
			  appGetConfig={this.props.appGetConfig}
			  prize={this.props.userRepositoryPrizes?.list.length > 0 ? this.props.userRepositoryPrizes?.list.filter((prize) => prize.position === 1)[0] : false}
            />
        );
    }
}

export default RecoverContainer;