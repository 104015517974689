import React from 'react';
import {Link} from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Preload from "react-preload";
import { motion } from "framer-motion";
import { getValue } from 'AppUtils/objects';
import getText from 'AppUtils/language';
import noop from 'lodash-es/noop';

import LoaderPage from "../../components/loader-page/LoaderPage";
import BoxShadow from "../../components/box-shadow/BoxShadow";
import BoxWave from "../../components/box-wave/BoxWave";
import BuyButton from "../../components/buy-button/BuyButton";

import ticket from './img/ticket.svg';
import banner1 from './img/banner1.png';
import banner2 from './img/banner2.png';
import banner3 from './img/banner3.png';

import 'react-tabs/style/react-tabs.css';
import './Store.scss';
import { formatAmount } from "../../utils/numbers";

const storeVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.2,
        }
    }
}

const descriptionVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.4,
            delay: 0.2
        }
    }
}

const listVariants = {
    hidden: {
        y: -40,
        opacity: 0,
    },
    visible: (custom) => ({
        y: 0,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.3,
            delay: custom * 0.1
        }
    })
}

const StoreComponent = (props) => {
    return (
        <>
            <LoaderPage isVisible={props.imagesLoaded && !props.filtersUiBanners.isLoading && !props.filtersUiCreditPackages.isLoading} />
            <Preload
              images={[banner1, banner2, banner3, ticket]}
              autoResolveDelay={5000}
              // onError={}
              onSuccess={props.handlePreloadImages}
              resolveOnError={true}
              mountChildren={false}
            >
            </Preload>

            {props.imagesLoaded && !props.filtersUiBanners.isLoading && !props.filtersUiCreditPackages.isLoading && (
              <motion.div
                className="store-component"
                variants={storeVariants}
                initial="hidden"
                animate="visible"
              >
                  <div className="content-wrapper-small">
                      <Tabs selectedIndex={props.tabIndex} onSelect={noop} className="custom-tabs" selectedTabClassName="selected-tab">
                          <TabList className="tabs-nav">
                              <Tab className="tab-title">
                                  <Link to={`/store/recommended`}>
                                      <BoxWave>
                                          {getText('Recommended')}
                                      </BoxWave>
                                  </Link>
                              </Tab>
                              {!props.disableBuyCredits &&
                                  <Tab className="tab-title">
                                      <Link to={`/store/tickets`}>
                                          <BoxWave>
                                              {getText('Tickets')}
                                          </BoxWave>
                                      </Link>
                                  </Tab>
                              }
                          </TabList>

                          <TabPanel>
                              <div className="banners-tab">
                                  {props.repositoryBanners.length > 0 && props.repositoryBanners.map((item, i) => (<motion.div
                                    key={item.id}
                                    variants={listVariants}
                                    custom={i + 2}
                                    initial="hidden"
                                    animate="visible"
                                  >
                                      {item.url ?
                                        <a
                                          href={item.url}
                                          target="_blank"
                                          className="banner-img"
                                        >
                                            <img src={`${item.image}?t=${window.performance.now()}`} alt=""/>
                                        </a>
                                        :
                                        <div
                                          className="banner-img"
                                        >
                                            <img src={`${item.image}?t=${window.performance.now()}`} alt=""/>
                                        </div>
                                      }
                                  </motion.div>))}
                              </div>
                          </TabPanel>
                          {/*created_at: "2020-09-21T10:17:56.279000Z"*/}
                          {/*currency: "€"*/}
                          {/*name: "1 ticket"*/}
                          {/*price: 1*/}
                          {/*quantity: 1*/}
                          {/*updated_at: "2020-09-21T10:17:56.279000Z"*/}
                          {/*id: "5f687dd4b0dbed7fb76d86a3"*/}
                          {!props.disableBuyCredits &&
                          <TabPanel>
                              <div className="tickets-tab">
                                  <motion.p
                                    variants={descriptionVariants}
                                    initial="hidden"
                                    animate="visible"
                                  >{getText('Buy more tickets, complete more challenges and get a head start in the leaderboard.')}</motion.p>
                                  {props.repositoryCreditPackages.length > 0 && props.repositoryCreditPackages.map((item, i) => (<motion.div
                                    key={item.id}
                                    variants={listVariants}
                                    custom={i + 1}
                                    initial="hidden"
                                    animate="visible"
                                  >
                                      <BoxShadow className={`tickets-box ${getValue(item, 'heading.template') ? (getValue(item, 'heading.template') == 1 ? 'with-title popular' : 'with-title best-value') : ''}`}>
                                          {getValue(item, 'heading.template') == 1 && <BoxWave className="title-ticket-box" isBig={true}>{getValue(item, `heading.text.${props.lang}`)}</BoxWave>}
                                          {getValue(item, 'heading.template') == 2 && <BoxWave className="title-ticket-box" isBig={true}>{getValue(item, `heading.text.${props.lang}`)}</BoxWave>}
                                          <div className="tickets-box-inner">
                                              <div className="tickets-no">
                                                  <p className="big-font">{item.quantity}<span>x</span></p>
                                                  <img src={ticket} alt=""/>
                                              </div>
                                              <div className="ticket-price">
                                                  <div className="cost">
                                                      <p className="big-font">{item.price}<span>{item.currency}</span></p>
                                                      <p className="ticket-cost">1 {getText('Ticket')} = {formatAmount(item.quantity/item.price, 2)}{item.currency}</p>
                                                  </div>
                                                  <BuyButton title={getText('BUY')} link="#" onClick={() => props.onCreditsBuy(item.id)} className="buy-btn"/>
                                              </div>
                                          </div>
                                      </BoxShadow>
                                  </motion.div>))}
                              </div>
                          </TabPanel>
                          }
                      </Tabs>
                  </div>
              </motion.div>
            )}
        </>
    );
};

export default StoreComponent;