import React from 'react';
import {Link} from "react-router-dom";
import { motion } from "framer-motion";
import Preload from "react-preload";
import getText from 'AppUtils/language';
import clsx from "clsx";
import logger from 'AppUtils/logging';

import LoaderPage from "../../components/loader-page/LoaderPage";

import './Map.scss';

//Images to preload
import bgMap from './img/background-map.jpg';
import bgStepCurrent from './img/bg-step-current-1.png';
import bgStepFuture from './img/bg-step-future-1.png';
import bgStepDone from './img/bg-step-done-1.png';
import bgStepInfo from './img/bg-step-info-1.png';
import bgBtnMap from "./img/bg-btn-world-map.svg";
import globe from "./img/globe.png";

const mapVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.2,
        }
    }
}

const stepsVariants = {
    hidden: {
        opacity: 0,
        scale: 0,
    },
    visible: (custom) => ({
        opacity: 1,
        scale: 1,
        transition: {
            type: 'tween',
            duration: 0.1,
            delay: custom * 0.1
        }
    })
}

const buttonRightVariants = {
    hidden: {
        x: '100vw',
        y: 400
    },
    visible: {
        x: 0,
        y: 0,
        transition: {
            type: 'tween',
            duration: 0.6
        }
    }
}

const MapComponent = (props) => {
    let userCities = props.userInfo && props.userInfo.character && props.userInfo.character.map && props.userInfo.character.map.cities ? props.userInfo.character.map.cities.filter((city) => city.id === props.id) : false;

    let activeCity = props.userInfo && props.userInfo.character && props.userInfo.character.map && props.userInfo.character.map.cities ? props.userInfo.character.map.cities.filter((city) => city.status === 'active') : false;
    console.log(activeCity)
    let singleCity = false;
    let userChallenges = false;
    if(props.id) {
        singleCity = props.repositoryMap && props.repositoryMap.cities ? props.repositoryMap.cities.filter((city) => city.id === props.id) : false;
        userChallenges = userCities[0] ? userCities[0].challenges : false;
    } else {
        singleCity = props.repositoryMap && props.repositoryMap.cities ? props.repositoryMap.cities.filter((city) => city.id === activeCity[0].id) : false;
        userChallenges = props.userInfo && props.userInfo.character && props.userInfo.character.map && props.userInfo.character.map.cities ? props.userInfo.character.map.cities.filter((city) => city.id === activeCity[0].id)[0].challenges : false;
    }

    const scrollTo = (ref) => {
        if (ref && ref.current /* + other conditions */) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    logger.info(userChallenges, 'userchallenges')

    const renderChallenges = () => {
        return singleCity[0].challenges.map((challenge, i) => {
            logger.info(challenge, 'challenge')
            const challengeStatus = userChallenges.filter((userChallenge) => userChallenge.id === challenge.id)[0] ? userChallenges.filter((userChallenge) => userChallenge.id === challenge.id)[0].status : 'locked';
            const challengeUrlParam = () => {
                let urlParam = '';
                switch(challenge.type) {
                    case 'swipeIt':
                        urlParam = 'swipe-it';
                        break;
                    case 'lineUp':
                        urlParam = 'line-up';
                        break;
                    case 'matchIt':
                        urlParam = 'match-it';
                        break;
                    case 'top3':
                        urlParam = 'top-3';
                        break;
                    default:
                }

                return urlParam;
            }

            return (
              <motion.div
                key={challenge.id}
                className={clsx(`step step-${i + 1}`, challengeStatus)}
                custom={i}
                variants={stepsVariants}
                initial="hidden"
                animate={props.imagesLoaded ? 'visible' : 'hidden'}
                style={{
                    top: challenge.layout.y + '%',
                    left: challenge.layout.x + '%'
                }}
                ref={challengeStatus === 'active' && i > 2 ? props.itemScrollTo : null}
              >
                  <div className="step-inner" onClick={() => props.handleStep(challengeUrlParam(), challengeStatus, challenge.id)}>
                      <span>{i + 1}</span>
                  </div>
              </motion.div>
            )
        })
    }
    return (
      <>
          <LoaderPage isVisible={props.imagesLoaded && !props.filtersUi.isLoading && props.mapLoaded} />
          {!props.filtersUi.isLoading &&
              <Preload
                images={[singleCity[0].image, bgStepCurrent, bgStepFuture, bgStepDone, bgStepInfo, bgBtnMap, globe]}
                autoResolveDelay={5000}
                // onError={}
                onSuccess={props.handlePreloadImages}
                resolveOnError={true}
                mountChildren={false}
              >
              </Preload>
          }

          {props.imagesLoaded && !props.filtersUi.isLoading && (
            <motion.div
              className={clsx("map-component", props.isBottom && 'scrolled-bottom')}
              variants={mapVariants}
              initial="hidden"
              animate="visible"
            >
                <img src={singleCity ? singleCity[0].image : ''} alt="" className="bg-map" onLoad={props.handleLoadMap} />

                <motion.div
                  className="button-map"
                  variants={buttonRightVariants}
                  initial="hidden"
                  animate="visible"
                >
                    <Link to="/world-map">
                        <img className="map-btn-bg" src={bgBtnMap} alt=""/>
                        <img className="img" src={globe} alt=""/>
                        <p>{getText('WORLD MAP')}</p>
                    </Link>
                </motion.div>

                {
                    singleCity && singleCity[0] && userChallenges && props.mapLoaded ?
                      renderChallenges()
                      : null
                }
            </motion.div>
          )}
      </>
    );
};

export default MapComponent;