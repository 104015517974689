import React from 'react';
import {Link} from "react-router-dom";
// import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import Preload from "react-preload";
import { motion } from "framer-motion";
import { MapInteractionCSS } from 'react-map-interaction';
import logger from 'AppUtils/logging';

import LoaderPage from "../../components/loader-page/LoaderPage";

import './WorldMap.scss';

const mapVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.2,
		}
	}
}

const WorldMapComponent = (props) => {

	let userCities = props.userInfo && props.userInfo.character && props.userInfo.character.map ? props.userInfo.character.map.cities : false;
	const userActiveCity = props.userInfo.character.map.activeCity;
	logger.info('userActiveCity', userActiveCity)
	let activeCity = false;

	let renderCities = () => {
		return props.repositoryMap.cities.map((city, index) => {
			logger.info('city')
			const cityStatus = userCities.filter((userCity) => userCity.id === city.id)[0] ? userCities.filter((userCity) => userCity.id === city.id)[0].status : 'locked';

			if(city.id === userActiveCity) {
				activeCity = city;

				// props.updateDefault({
				// 	scale: 11,
				// 	translation: { x: activeCity.layout.x * 11, y: activeCity.layout.y * 11}
				// });
			}

			if(cityStatus !== 'active' && cityStatus !== 'unlocked') return null;
			return (
			  <div
				key={city.id}
				className={`map-place ${city.slug}`}
				style={{
					top: `${city.layout.y}%`,
					left: `${city.layout.x}%`
				}}
				ref={city.id === userActiveCity ? props.activeCityRef : null}
				onClick={(event) => props.uiRedirect({
					pathname: `/map/${city.id}`
				})}
				onTouchEnd={(event) => props.uiRedirect({
					pathname: `/map/${city.id}`
				})}
			  >
				  <img src={city.icon} alt={city.slug}/>
			  </div>
			)
		})
	}

	logger.info(props.activeCityRef, 'props.activeCityRef')

	return (
	  <>
		  <h1>{activeCity.name}</h1>
		  <LoaderPage isVisible={props.imagesLoaded} />

		  {!props.filtersUi.isLoading &&
			  <Preload
				images={[props.repositoryMap.image]}
				autoResolveDelay={5000}
				// onError={}
				onSuccess={props.handlePreloadImages}
				resolveOnError={true}
				mountChildren={false}
			  >
			  </Preload>
		  }

		  {props.imagesLoaded && !props.filtersUi.isLoading && (
			<motion.div
			  className="world-map-component content-wrapper"
			  variants={mapVariants}
			  initial="hidden"
			  animate="visible"
			  ref={props.mapComponentRef}
			>
				{/*<PinchZoomPan*/}
				{/*  initialScale={4}*/}
				{/*  minScale={4}*/}
				{/*  maxScale={11}*/}
				{/*  zoomButtons={false}*/}
				{/*  doubleTapBehavior="zoom"*/}
				{/*>*/}
				{/*	<div className="map-wrapper">*/}
				{/*		<img src={bgMap} alt="map" />*/}
				{/*		<Link to="/profile" className="map-place barcelona">*/}
				{/*			<img src={barcelona} alt=""/>*/}
				{/*		</Link>*/}
				{/*		<Link to="/profile" className="map-place berlin">*/}
				{/*			<img src={berlin} alt=""/>*/}
				{/*		</Link>*/}
				{/*		<Link to="/profile" className="map-place capetown">*/}
				{/*			<img src={capetown} alt=""/>*/}
				{/*		</Link>*/}
				{/*		<Link to="/profile" className="map-place dubai">*/}
				{/*			<img src={dubai} alt=""/>*/}
				{/*		</Link>*/}
				{/*		<Link to="/profile" className="map-place lisabona">*/}
				{/*			<img src={lisabona} alt=""/>*/}
				{/*		</Link>*/}
				{/*		<Link to="/" className="map-place londra">*/}
				{/*			<img src={londra} alt=""/>*/}
				{/*		</Link>*/}
				{/*		<Link to="/profile" className="map-place paris">*/}
				{/*			<img src={paris} alt=""/>*/}
				{/*		</Link>*/}
				{/*		<Link to="/profile" className="map-place rio">*/}
				{/*			<img src={rio} alt=""/>*/}
				{/*		</Link>*/}
				{/*		<Link to="/profile" className="map-place roma">*/}
				{/*			<img src={roma} alt=""/>*/}
				{/*		</Link>*/}
				{/*		<Link to="/profile" className="map-place singapore">*/}
				{/*			<img src={singapore} alt=""/>*/}
				{/*		</Link>*/}
				{/*	</div>*/}
				{/*</PinchZoomPan>*/}

				<MapInteractionCSS
				  value={props.value}
				  defaultValue={{
					  scale: 11,
					  translation: { x: -400, y: -200 }
				  }}
				  minScale={4}
				  maxScale={11}
				  onChange={(value) => {props.handleMap(value)}}
				  translationBounds={props.translationBounds}
				  showControls={true}
				  controlsClass="map-controls"
				  minusBtnClass="world-zoom-out"
				>
					<div className="map-wrapper">
						<img
						  src={props.repositoryMap ? props.repositoryMap.image : ''}
						  alt="map"
						  ref={props.mapRef}
						  onLoad={() => props.setDefault({
							  scale: 11,
							  translation: { x: (-props.activeCityRef.current.offsetLeft - props.activeCityRef.current.clientWidth/2) * 11 + props.mapComponentRef.current.clientWidth/2, y: (-props.activeCityRef.current.offsetTop - props.activeCityRef.current.clientWidth/2) * 11 + props.mapComponentRef.current.clientHeight/2 }
						  })}
						/>

						{props.repositoryMap.cities ? renderCities() : null}

						{/*<div*/}
						{/*  className="map-place barcelona"*/}
						{/*>*/}
						{/*	<img src={barcelona} alt=""/>*/}
						{/*</div>*/}
						{/*<div*/}
						{/*  className="map-place berlin">*/}
						{/*	<img src={berlin} alt=""/>*/}
						{/*</div>*/}
						{/*<div*/}
						{/*  className="map-place capetown"*/}
						{/*  onClick={(event) => console.log(event, 'eventClick')}*/}
						{/*  onTouchEnd={(event) => console.log(event, 'touoch end')}*/}
						{/*>*/}
						{/*	<img src={capetown} alt=""/>*/}
						{/*</div>*/}
						{/*<div*/}
						{/*  className="map-place dubai"*/}
						{/*  onClick={(event) => console.log(event, 'eventClick')}*/}
						{/*  onTouchEnd={(event) => console.log(event, 'touoch end')}*/}
						{/*>*/}
						{/*	<img src={dubai} alt=""/>*/}
						{/*</div>*/}
						{/*<div*/}
						{/*  className="map-place lisabona"*/}
						{/*  onClick={(event) => console.log(event, 'eventClick')}*/}
						{/*  onTouchEnd={(event) => console.log(event, 'touoch end')}*/}
						{/*>*/}
						{/*	<img src={lisabona} alt=""/>*/}
						{/*</div>*/}
						{/*<div*/}
						{/*  className="map-place londra"*/}
						{/*  onClick={(event) => console.log(event, 'eventClick')}*/}
						{/*  onTouchEnd={(event) => console.log(event, 'touoch end')}*/}
						{/*>*/}
						{/*	<img src={londra} alt=""/>*/}
						{/*</div>*/}
						{/*<div*/}
						{/*  className="map-place paris"*/}
						{/*  onClick={(event) => console.log(event, 'eventClick')}*/}
						{/*  onTouchEnd={(event) => console.log(event, 'touoch end')}*/}
						{/*>*/}
						{/*	<img src={paris} alt=""/>*/}
						{/*</div>*/}
						{/*<div*/}
						{/*  className="map-place rio"*/}
						{/*  onClick={(event) => console.log(event, 'eventClick')}*/}
						{/*  onTouchEnd={(event) => console.log(event, 'touoch end')}*/}
						{/*>*/}
						{/*	<img src={rio} alt=""/>*/}
						{/*</div>*/}
						{/*<div*/}
						{/*  className="map-place roma"*/}
						{/*  onClick={(event) => console.log(event, 'eventClick')}*/}
						{/*  onTouchEnd={(event) => console.log(event, 'touoch end')}*/}
						{/*>*/}
						{/*	<img src={roma} alt=""/>*/}
						{/*</div>*/}
						{/*<div*/}
						{/*  className="map-place singapore"*/}
						{/*  onClick={(event) => console.log(event, 'eventClick')}*/}
						{/*  onTouchEnd={(event) => console.log(event, 'touoch end')}*/}
						{/*>*/}
						{/*	<img src={singapore} alt=""/>*/}
						{/*</div>*/}
					</div>
				</MapInteractionCSS>

			</motion.div>
		  )}
	  </>
	)
};

export default WorldMapComponent;