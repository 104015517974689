import getText from 'AppUtils/language';
import {HtmlParser} from "./language";

export const validate = element => {
	let error = [true, ''];

	if (element.validation.numeric) {
		const valid = !isNaN(element.value);
		const message = `${!valid ? getText('Must be numeric.') : ''}`;
		error = !valid ? [valid, message] : error;
	}

	if (element.validation.email) {
		const valid = /\S+@\S+\.\S+/.test(element.value);
		const message = `${!valid ? getText('Must be a valid email.') : ''}`;
		error = !valid ? [valid, message] : error;
	}

	if (element.validation.minlength) {
		const valid = element.value.length >= element.validation.minlength;
		const text = element.config.name == 'phone' ? getText("Phone number must be at least {0} digits.").replace('{0}', element.validation.minlength) : getText("Password must be at least {0} characters.").replace('{0}', element.validation.minlength);
		const message = !valid
		  ? text
		  : '';
		error = !valid ? [valid, message] : error;
	}

	if (element.validation.required) {
		const valid = element.value.toString().trim() !== '';
		const message = `${!valid ? getText('This field is required.') : ''}`;
		error = !valid ? [valid, message] : error;
	}

	return error;
};

export const validateSubmit = formData => {
	let counterValid = 0;
	Object.keys(formData).forEach(key => {
		let validData = validate(formData[key]);
		formData[key].valid = validData[0];
		formData[key].validationMessage = validData[1];

		formData[key].touched = true;

		if (!formData[key].valid) {
			counterValid++;
		}
	});

	if (counterValid > 0) {
		return formData;
	} else {
		return false;
	}
};