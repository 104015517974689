import React from 'react';
import Preload from "react-preload";
import { motion } from "framer-motion";
import CountUp from 'react-countup';
import getText from 'AppUtils/language';

import LoaderPage from "../../../components/loader-page/LoaderPage";
import WrapperGame from "../subcomponents/wrapper-game/WrapperGame";
import Button from "../../../components/button/Button";

import timer from "./img/timer.svg";
import target from "./img/accuracy.svg";
import first from "./img/1st.svg";

import './ResultGame.scss';

const resultGameVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.2,
        }
    }
}

const titleVariants = {
    hidden: {
        opacity: 0,
        scale: 0,
    },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            type: 'tween',
            duration: 0.2,
            delay: 1.3
        }
    }
}

const listVariants = {
    hidden: {
        y: -40,
        opacity: 0,
    },
    visible: (custom) => ({
        y: 0,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.3,
            delay: custom * 0.1
        }
    })
}

const totalVariants = {
    hidden: {
        y: 40,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.2,
            delay: 0.6
        }
    }
}

const buttonVariants = {
    hidden: {
        y: '100vh'
    },
    visible: {
        y: 0,
        transition: {
            type: 'tween',
            duration: 0.6,
            delay: 1.2
        }
    }
}

const ResultGameComponent = (props) => {

    let correctAnswers = [];
    if(props.challengePlay) {
        correctAnswers = props.challengePlay.rounds_status.filter((round) => {
            if(round) return round;
        });
    }

    return (
        <>
            <LoaderPage isVisible={props.imagesLoaded && props.challengePlay} />
            <Preload
              images={[timer, target, first]}
              autoResolveDelay={5000}
              // onError={}
              onSuccess={props.handlePreloadImages}
              resolveOnError={true}
              mountChildren={false}
            >
            </Preload>

            {props.imagesLoaded && props.challengePlay && props.challengePlay.points && (
              <motion.div
                className="result-game-component"
                variants={resultGameVariants}
                initial="hidden"
                animate="visible"
              >
                  <div className="content-wrapper">
                      <WrapperGame>
                          <motion.p
                            className="title-result"
                            variants={titleVariants}
                            initial="hidden"
                            animate="visible"
                          >{getText('PERFECT!')}</motion.p>
                          <div className="results-list">
                              <motion.div
                                className="item"
                                custom={2}
                                variants={listVariants}
                                initial="hidden"
                                animate="visible"
                              >
                                  <div className="img">
                                      <img src={timer} alt=""/>
                                  </div>
                                  <div className="info">
                                      <p><strong>{correctAnswers.length > 0 ? ((correctAnswers.length/(props.challengePlay.total_rounds)) * 100).toFixed(0) : 0}% {getText('Accuracy')}</strong></p>
                                      <p className="description">{correctAnswers.length}/{props.challengePlay.total_rounds} {getText('correct choices')}</p>
                                  </div>
                                  <div className="xp">
                                      <p>{props.challengePlay.points.accuracy}<span>XP</span></p>
                                  </div>
                              </motion.div>

                              <motion.div
                                className="item"
                                custom={3}
                                variants={listVariants}
                                initial="hidden"
                                animate="visible"
                              >
                                  <div className="img">
                                      <img src={target} alt=""/>
                                  </div>
                                  <div className="info">
                                      <p><strong>{getText('Time bonus')}</strong></p>
                                      <p className="description">{props.challengePlay.init_timer - props.challengePlay.elapsed_time > 0 ? props.challengePlay.init_timer - props.challengePlay.elapsed_time : 0} {getText('seconds remaining')}</p>
                                  </div>
                                  <div className="xp">
                                      <p>{props.challengePlay.points.time_bonus}<span>XP</span></p>
                                  </div>
                              </motion.div>

                              <motion.div
                                className="item"
                                custom={4}
                                variants={listVariants}
                                initial="hidden"
                                animate="visible"
                              >
                                  <div className="img">
                                      <img src={first} alt=""/>
                                  </div>
                                  <div className="info">
                                      <p><strong>{getText('First time bonus')}</strong></p>
                                  </div>
                                  <div className="xp">
                                      <p>{props.challengePlay.points.first_time_bonus}<span>XP</span></p>
                                  </div>
                              </motion.div>
                          </div>

                          <motion.div
                            className="total"
                            variants={totalVariants}
                            initial="hidden"
                            animate="visible"
                          >
                              <p>{getText('TOTAL')}</p>
                              <p className="xp">
                                  <CountUp delay={0.8} start={0} end={props.challengePlay.points.total} duration={0.6} />
                                  <span>XP</span>
                              </p>
                          </motion.div>
                      </WrapperGame>
                  </div>

                  <motion.div
                    variants={buttonVariants}
                    initial="hidden"
                    animate="visible"
                  >
                      <Button link="/map" title="OK" slim={true}/>
                  </motion.div>
              </motion.div>
            )}
        </>
    );
};

export default ResultGameComponent;