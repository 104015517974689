import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import isEqual from "lodash-es/isEqual";

import HeaderComponent from "./HeaderComponent";
import { userInfo } from "../../containers/user/store/selectors";

const stateToProps = state => ({
	userInfo: userInfo(state),
});

@connect(stateToProps, null)
class HeaderContainer extends Component {

	state = {
		lvlUp: 0,
		oldLevelPercentage: null,
		oldPoints: false,
		levelPercentage: this.props.userInfo && this.props.userInfo.character && this.props.userInfo.character.level && this.props.userInfo.character.level.percentage ? this.props.userInfo.character.level.percentage : 0,
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(!isEqual(prevProps.userInfo, this.props.userInfo) && this.props.userInfo) {
			if(this.props.userInfo.character && this.props.userInfo.character.level) {
				this.setState({
					levelPercentage: this.props.userInfo.character.level.percentage
				})
			}
			if(prevProps.userInfo && prevProps.userInfo.character && prevProps.userInfo.character.level) {
				this.setState({
					oldPoints: prevProps.userInfo.character.points,
					oldLevelPercentage: prevProps.userInfo.character.level.percentage,
				})
			}
		}
	}

	setLvlUp = () => {
		setTimeout(() => {
			this.setState({
				lvlUp: this.state.lvlUp + 1
			})
		}, 1.4)
	}

	setValue = (newState) => {
		this.setState({
			...this.state,
			...newState,
		})
	}

	render() {
		return (
		  <HeaderComponent
			  toggleMenu={this.props.toggleMenu}
			  hasSubheader={this.props.hasSubheader}
			  hasStats={this.props.hasStats}
			  hasHint={this.props.hasHint}
			  title={this.props.title}
			  backLink={this.props.backLink}
			  userInfo={this.props.userInfo}
			  oldPoints={this.state.oldPoints}
			  oldLevelPercentage={this.state.oldLevelPercentage}
			  levelPercentage={this.state.levelPercentage}
			  setLvlUp={this.setLvlUp}
			  lvlUp={this.state.lvlUp}
			  setValue={this.setValue}
			  handleHint={this.props.handleHint}
			  gameType={this.props.gameType}
			  isHint={this.props.isHint}
			  hideBack={this.props.hideBack}
			  gameReady={this.props.gameReady}
		  />
		);
	}
}

export default HeaderContainer;