import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import Timer from "react-compound-timer";
import logger from 'AppUtils/logging';

import WrongAnswersComponent from "./WrongAnswersComponent";

import {uiRedirect, uiToggleModal} from "../../../../../store/actions";
import {gamesSocket} from "../../../store/selectors";
import {appGetInterfaceLang} from "../../../../../store/selectors";

import {
	socketLoadCloseConnection,
	socketLoadFinishChallenge,
	socketLoadStartChallenge,
	socketLoadUpdateChallenge
} from "../../../store/actions";
import isEqual from "lodash-es/isEqual";
import getText from "../../../../../utils/language";
import {userInfo} from "../../../../user/store/selectors";
import {userLoadInfo} from "../../../../user/store/actions";

const stateToProps = state => ({
	gamesSocket: gamesSocket(state),
	appGetInterfaceLang: appGetInterfaceLang(state),
	userInfo: userInfo(state),
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	userLoadInfo: () => dispatch(userLoadInfo()),
	socketLoadUpdateChallenge: (data) => dispatch(socketLoadUpdateChallenge(data)),
	socketLoadStartChallenge: () => dispatch(socketLoadStartChallenge()),
	socketLoadFinishChallenge: () => dispatch(socketLoadFinishChallenge()),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	socketLoadCloseConnection: () => dispatch(socketLoadCloseConnection()),
});

@connect(stateToProps, actionsToProps)
class WrongAnswersContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			challengePlay: false,
			imagesLoaded: false,
			correct:0,
			incorrect:0,
			answerChoice: false,
			timeFinished: 0,
			answers: [null, null, null, null],
			secondsNegative: 0,
			validating: false,
			availableSkills: props.userInfo.character.skills.items[this.props.location.state.gameType].available,
		}

		this.handleClick = this.handleClick.bind(this)

		if(!this.props.location.start) {
			this.props.uiRedirect({
				pathname: `/challenge/instructions/${this.props.match.params.challengeId}`
			})
		} else if(!this.props.match.params.challengeId) {
			this.props.uiRedirect({
				pathname: '/map'
			})
		}

		this.interval = false;
	}

	componentDidMount() {
		// this.setState({
		// 	dataSet: [
		// 		{
		// 			id: 1,
		// 			question: "Intrebare 1?",
		// 			answers: ["answer 1", "answer 2", "answer 3", "answer 4", "answer 5", "answer 6"],
		// 			touched: false,
		// 			correct: [2,3,4],
		// 		},
		// 		{
		// 			id: 2,
		// 			question: "Intrebare 2?",
		// 			answers: ["answer 1", "answer 2", "answer 3", "answer 4", "answer 5", "answer 6"],
		// 			touched: false,
		// 			correct: [0,1,2],
		// 		},
		// 		{
		// 			id: 2,
		// 			question: "Intrebare 3?",
		// 			answers: ["answer 1", "answer 2", "answer 3", "answer 4", "answer 5", "answer 6"],
		// 			touched: false,
		// 			correct: [0,1,2],
		// 		},
		// 		{
		// 			id: 2,
		// 			question: "Intrebare 4?",
		// 			answers: ["answer 1", "answer 2", "answer 3", "answer 4", "answer 5", "answer 6"],
		// 			touched: false,
		// 			correct: [0,1,2],
		// 		}
		// 	]
		// })
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.gamesSocket.error !== this.props.gamesSocket.error && this.props.gamesSocket.error) {
			if(this.state.isHint) {
				this.props.handleHint(false, false);
			}

			this.openModal(getText('Error'), getText('Something went wrong. Please try again later.'), true, getText('Retry'), false, false, true, () => {
				this.props.uiRedirect({
					pathname: `/map`
				})
				this.closeModal();
			});
		} else if(!this.props.gamesSocket.error) {
			if (!isEqual(prevProps.gamesSocket, this.props.gamesSocket) && this.props.gamesSocket && this.props.gamesSocket.data && this.props.gamesSocket.data.challengePlay && this.props.gamesSocket.call === 'start_challenge') {
				this.props.checkGameReady(true);

				let answers = [];
				for(let i = 0; i < this.props.gamesSocket.data.challengePlay.total_rounds; i++) {
					if(typeof this.props.gamesSocket.data.challengePlay.rounds_status[i] !== "undefined") {
						answers[i] = this.props.gamesSocket.data.challengePlay.rounds_status[i];
					} else {
						answers[i] = null;
					}
				}

				if(parseInt(this.props.gamesSocket.data.challengePlay.timer) <= 0) {
					this.setState({
						secondsNegative: this.props.gamesSocket.data.challengePlay.timer
					}, () => {
						this.countNegative();
					})
				}

				let newAnswerChoice = [];

				for(let i = 0; i < this.props.gamesSocket.data.challengePlay.total_rounds; i++) {
					newAnswerChoice.push([]);
				}

				this.setState({
					challengePlay: this.props.gamesSocket.data.challengePlay,
					answers,
					answerChoice: newAnswerChoice
				})

				this.props.timer.setCheckpoints([
					{
						time: 6000,
						callback: () => {
							this.setState({
								timeCritical: true
							})
						},
					},
					{
						time: 0,
						callback: () => {
							this.countNegative();
						},
					},
				]);

				this.props.timer.setTime((this.props.gamesSocket.data.challengePlay.timer <= 0 ? 0 : this.props.gamesSocket.data.challengePlay.timer * 1000 + 500));

				this.props.getTitle(this.props.gamesSocket.data.challengePlay.name[this.props.appGetInterfaceLang])
			}

			if(!isEqual(prevProps.gamesSocket.data, this.props.gamesSocket.data) && this.props.gamesSocket && this.props.gamesSocket.data && this.props.gamesSocket.data.challengePlay && this.props.gamesSocket.call === 'update_challenge') {
				let answers = [...this.state.answers];

				answers[this.props.gamesSocket.data.challengePlay.current_round - 1] = this.props.gamesSocket.data.challengePlay.rounds_status[this.props.gamesSocket.data.challengePlay.current_round - 1];

				if(prevProps.gamesSocket.data.challengePlay.current_round + 1 === this.props.gamesSocket.data.challengePlay.total_rounds) {
					answers[this.props.gamesSocket.data.challengePlay.current_round] = this.props.gamesSocket.data.challengePlay.rounds_status[this.props.gamesSocket.data.challengePlay.current_round];
				}

				if(this.state.isHint) {
					this.props.userLoadInfo();
				}

				this.setState({
					answers,
				})

				setTimeout(() => {
					if(this.state.toFinish) {
						this.props.socketLoadFinishChallenge();
					} else {

						this.handleNextQuestion(this.props.timer.getTime()/1000);

						this.setState({
							challengePlay: this.props.gamesSocket.data.challengePlay,
						})
						this.props.handleHint(false, false);
					}
				}, 600)
			}

			if(!isEqual(prevProps.gamesSocket, this.props.gamesSocket) && this.props.gamesSocket.gameFinished) {
				this.props.handleHint(false, false);
				this.props.uiRedirect({
					pathname: `/result-game/${this.state.challengePlay.challenge_id}`,
					state: {
						finish: true,
						challengePlay: this.props.gamesSocket.data.challengePlay
					}
				})
			}
		}

		if(prevState.imagesLoaded !== this.state.imagesLoaded && this.state.imagesLoaded) {
			setTimeout(() => {
				this.props.socketLoadStartChallenge();
				this.props.timer.start();
			}, 800)
		}
	}

	componentWillUnmount() {
		this.props.socketLoadCloseConnection();
		this.props.checkGameReady(false);

		clearInterval(this.interval);
	}

	openModal = (title, message, button, buttonText, button2, button2Text, restrictClose, callback, callback2) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, restrictClose, callback } );
	};

	closeModal = () => {
		this.props.uiToggleModal({}, { toggle: false }, { title: '', message: '' });
	};

	countNegative = () => {
		let stateCopy = {...this.state};
		this.interval = setInterval(() => {
			if(this.state.pauseNegative) {
				// clearInterval(interval)
			} else {
				this.setState({
					secondsNegative: stateCopy.secondsNegative--
				})
			}
		}, 1000);

	}

	handleClick(choice, isHint) {
		let newAnswerChoice = [...this.state.answerChoice];

		if(isHint) {
			this.props.handleHint(false, true);

			newAnswerChoice[this.state.challengePlay.current_round] = [null, null, null];
		} else {
			// let newDataSet = [...this.state.dataSet];
			// newDataSet[this.state.current].touched = true;
			newAnswerChoice[this.state.challengePlay.current_round] = newAnswerChoice[this.state.challengePlay.current_round] ? newAnswerChoice[this.state.challengePlay.current_round] : [];
			if(typeof choice === 'undefined') {
				newAnswerChoice[this.state.challengePlay.current_round] = [null, null, null];
			} else {
				newAnswerChoice[this.state.challengePlay.current_round].push(choice);
			}
		}


		this.setState({
			touched: true,
			answerChoice: newAnswerChoice,
			// dataSet: newDataSet,
		}, () => {
			if (this.state.answerChoice[this.state.challengePlay.current_round].length === 3) {
				logger.info(this.state.answerChoice)

				let answers = [...this.state.answers];
				answers[this.state.challengePlay.current_round] = 1;

				this.setState({
					answers,
				})

				let finalCountdown = parseInt(this.props.timer.getTime()/1000);

				if(finalCountdown <= 0) {
					finalCountdown = this.state.secondsNegative;
				}
				this.props.timer.pause();

				if(this.state.challengePlay.current_round + 1 === this.state.challengePlay.total_rounds) {
					this.setState({
						toFinish: true
					})
				}

				this.setState({
					pauseNegative: true,
					validating: true,
					isHint: isHint,
				}, () => {
					if(!isHint) {
						this.props.socketLoadUpdateChallenge({ challengeCurrentRound: this.state.challengePlay.current_round, answer: this.state.answerChoice[this.state.challengePlay.current_round], countdown: finalCountdown });
					} else {
						this.props.socketLoadUpdateChallenge({ challengeCurrentRound: this.state.challengePlay.current_round, autosolve: true, countdown: finalCountdown });
					}
				})
			}
		})
	}

	handleNextQuestion = (timeRemaining) => {
		logger.info(timeRemaining, 'timerem');

		this.setState({
			timeFinished: timeRemaining ? this.state.timeFinished + (60 - timeRemaining) : this.state.timeFinished + 60,
			touched: false,
			validating: false,
			isHint: false,
		}, () => {
			// this.props.timer.reset();
			if(this.state.toFinish) {
				this.props.socketLoadFinishChallenge();
			} else {
				this.setState({
					pauseNegative: false,
				})

				if(this.props.timer.getTime() > 0) {
					this.props.timer.start();
				}
			}
		})
	}

	returnTimeLeft = () => {
		return (
		  <>
			  <Timer.Minutes formatValue={value => ("0" + value).slice(-2)} />:<Timer.Seconds formatValue={value => ("0" + value).slice(-2)} />
		  </>
		);
	}

	handlePreloadImages = () => {
		this.setState({
			imagesLoaded: true
		})
	}

	render() {
		return (
		  <WrongAnswersComponent
			{...this.state}
			returnTimeLeft={this.returnTimeLeft}
			handleClick={this.handleClick}
			imagesLoaded={this.state.imagesLoaded}
			handlePreloadImages={this.handlePreloadImages}
			lang={this.props.appGetInterfaceLang}
			runHint={this.props.runHint}
		  />
		);
	}
}

const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
  <Timer {...timerProps}>
	  {timerRenderProps =>
		<WrappedComponent {...wrappedComponentProps} timer={timerRenderProps} />}
  </Timer>
);

const TimerHOC = withTimer({
	direction: 'backward',
	initialTime: 60000,
	startImmediately: false,
})(WrongAnswersContainer);

export default TimerHOC;