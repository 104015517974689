import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import getText, { HtmlParser } from "AppUtils/language"

import HomeComponent from "./HomeComponent";
import {userInfo, userRepositoryPrizes} from "../user/store/selectors";
import {uiToggleModal} from "../../store/actions";

const stateToProps = state => ({
	userInfo: userInfo(state),
	userRepositoryPrizes: userRepositoryPrizes(state),
});

const actionsToProps = dispatch => ({
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
});

@connect(stateToProps, actionsToProps)
class HomeContainer extends Component {

	openModal = (title, message, button, buttonText, button2, button2Text, callback) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, button2, button2Text, callback  } );
	};

	handleNotEligible = () => {
		this.openModal(getText('Error'), <HtmlParser html="You are not eligible to participate. <br/> Check you balance and try again" />);
	}

	render() {
		return (
		  <HomeComponent
			windowHeight={this.props.windowHeight}
			userInfo={this.props.userInfo}
			handleNotEligible={this.handleNotEligible}
			prize={this.props.userRepositoryPrizes?.list.length > 0 ? this.props.userRepositoryPrizes?.list.filter((prize) => prize.position === 1)[0] : false}
		  />
		);
	}
}

export default HomeContainer;