import React, { Component } from 'react';
import Observable from 'Observable';
import connect from 'react-redux/es/connect/connect';

import TutorialComponent from "./TutorialComponent";
import { appGetInterfaceLang } from "../../store/selectors";

import { apiGet } from 'AppUtils/api';

const stateToProps = state => ({
	lang: appGetInterfaceLang(state),
});

@connect(stateToProps, null)
class TutorialContainer extends Component {

	state = {
		openTutorial: false,
		imagesLoaded: false,
		items: [],
	}

	handlePreloadImages = () => {
		this.setState({
			imagesLoaded: true
		})
	};

	handleOpenTutorial = (index) => {
		if (this.state.openTutorial !== index) {
			this.setState({
				openTutorial: index
			})
		} else {
			this.setState({
				openTutorial: false
			})
		}
	}

	componentDidMount() {
		apiGet('/repository/tutorial')
		  .catch((e) => {
			  return Observable.empty();
		  })
		  .mergeMap(res => {
			  let resp = Promise.resolve({ error: true });
			  if (res.ok) {
				  resp = res.json();
			  }

			  return Promise.resolve({ resp, statusCode: res.status });
		  })
		  .subscribe((result) => {
			  const { resp, statusCode } = result;

			  resp.then(response => {
				  if (response && !response.error) {
						this.setState({
							items: response.items
						});
				  }
			  });
		  });
	}

	render() {
		return (
		  <TutorialComponent
			lang={this.props.lang}
			imagesLoaded={this.state.imagesLoaded}
			handlePreloadImages={this.handlePreloadImages}
			openTutorial={this.state.openTutorial}
			handleOpenTutorial={this.handleOpenTutorial}
			items={this.state.items}
		  />
		);
	}
}

export default TutorialContainer;