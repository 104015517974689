import React from 'react';
import { motion } from "framer-motion";
import Preload from "react-preload";
import getText from 'AppUtils/language';

import Button from "../../../../../components/button/Button";
import LoaderPage from "../../../../../components/loader-page/LoaderPage";

import globy from '../../../../home/img/globy.svg';

//Images to preload
import instructionsImage from './img/logo-bonuskit.png';

import '../../../GameInstructions.scss';

const InstructionsVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.2,
        }
    }
}

const buttonVariants = {
    hidden: {
        y: '100vh'
    },
    visible: {
        y: 0,
        transition: {
            type: 'tween',
            duration: 0.6,
            delay: 0.2
        }
    }
}

const earthVariants = {
    hidden: {
        x: '100vw'
    },
    visible: {
        x: 0,
        transition: {
            type: 'tween',
            duration: 0.6,
            delay: 0.2
        }
    }
}

const dialogVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.6,
            delay: 0.2
        }
    }
}

const gameIconVariants = {
    hidden: {
        scale: 0,
        rotate: '2000deg',
    },
    visible: {
        scale: 1,
        rotate: '0deg',
        transition: {
            type: 'tween',
            duration: 1.2,
            delay: 0.2
        }
    }
}

const LuckyCageInstructionsComponent = (props) => {
    return (
        <>
            <Preload
              images={[instructionsImage, globy]}
              autoResolveDelay={5000}
              // onError={}
              onSuccess={props.handlePreloadImages}
              resolveOnError={true}
              mountChildren={false}
            >
            </Preload>

            {props.imagesLoaded && (
              <motion.div
                className="bonus-game-instructions-component game-instructions"
                variants={InstructionsVariants}
                initial="hidden"
                animate="visible"
              >
                  <div className="instructions-image">
                      <motion.img
                        src={instructionsImage}
                        alt=""
                        variants={gameIconVariants}
                        initial="hidden"
                        animate={props.imagesLoaded ? 'visible' : 'hidden'}
                      />
                  </div>

                  <div className="instructions-globy">
                      <motion.div
                        className="instructions-message"
                        variants={dialogVariants}
                        initial="hidden"
                        animate={props.imagesLoaded ? 'visible' : 'hidden'}
                      >
                          <p>{props.gamesStartBonus.bonus_game ? (props.shakeSupport ? props.gamesStartBonus.bonus_game.description[props.lang].replace('$action', getText('Shake')) : props.gamesStartBonus.bonus_game.description[props.lang].replace('$action', getText('Tap'))) : ''}</p>
                      </motion.div>
                      <motion.img
                        className="globy"
                        src={globy} alt=""
                        variants={earthVariants}
                        initial="hidden"
                        animate={props.imagesLoaded ? 'visible' : 'hidden'}
                      />
                  </div>

                  <motion.div
                    className="cta"
                    variants={buttonVariants}
                    initial="hidden"
                    animate={props.imagesLoaded ? 'visible' : 'hidden'}
                    onClick={() => props.uiRedirect({
                        pathname: '/bonus-challenge/lucky-cage',
                        state: {
                            playId: props.playId,
                            notificationId: props.notificationId
                        }
                    })}
                  >
                      <Button title={getText('START')} slim={true}/>
                  </motion.div>
              </motion.div>
            )}
        </>
    );
};

export default LuckyCageInstructionsComponent;