import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import isEqual from "react-fast-compare";
import { getValue } from 'AppUtils/objects';
import getText from 'AppUtils/language';

import AchievementsComponent from "./AchievementsComponent";
import { userInfo, userRepositoryAchievements, userRepositorySkills } from "../user/store/selectors";
import { userRepositoryLoadAchievements, userRepositoryLoadSkills } from "../user/store/actions";
import { appGetInterfaceLang } from "../../store/selectors";
import Layout from "../../layout/Layout";

const stateToProps = state => ({
	userRepositoryAchievements: userRepositoryAchievements(state),
	userInfo: userInfo(state),
	appGetInterfaceLang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({
	userRepositoryLoadAchievements: () => dispatch(userRepositoryLoadAchievements()),
});

@connect(stateToProps, actionsToProps)
class AchievementsContainer extends Component {

    state = {
        imagesLoaded: false,
		repositoryAchievements: [],
		filtersUi: {
			isLoading: false
		},
    };

    handlePreloadImages = () => {
        this.setState({
            imagesLoaded: true
        })
    }

	componentDidMount() {
		this.props.userRepositoryLoadAchievements();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { userRepositoryAchievements } = this.props;

		if (userRepositoryAchievements.isLoading == false && userRepositoryAchievements.list.length > 0 && (this.state.repositoryAchievements.length == 0 || !isEqual(userRepositoryAchievements.list, prevProps.userRepositoryAchievements.list))) {
			this.setState(prevState => ({
				repositoryAchievements: [...prevState.repositoryAchievements, ...userRepositoryAchievements.list],
				hasMore: userRepositoryAchievements.list.length > 0,
				filtersUi: {
					...prevState.filtersUi,
					isLoading: false
				},
			}));
		}
	}

	getTabIndex = (slug) => {
		let index = 0;

		if (slug == 'unlocked') {
			index = 0;
		} else if (slug == 'locked') {
			index = 1;
		}

		return index;
	};

    render() {
        const { repositoryAchievements, filtersUi } = this.state;
		const { userInfo, appGetInterfaceLang } = this.props;
		const { slug = '' } = getValue(this.props, 'match.params', {});

        return (
		  <Layout hasFooter={false} hasStats={false} hasSubheader={true} title={getText('Achievements')} showMenu={this.props.showMenu} toggleMenu={this.props.toggleMenu} backLink={this.props.backLink}>
			  <AchievementsComponent
				tabIndex={this.getTabIndex(slug)}
				imagesLoaded={this.state.imagesLoaded}
				handlePreloadImages={this.handlePreloadImages}
				userInfo={userInfo}
				repositoryAchievements={repositoryAchievements}
				filtersUi={filtersUi}
				appGetInterfaceLang={appGetInterfaceLang}
			  />
		  </Layout>
        );
    }
}

export default AchievementsContainer;