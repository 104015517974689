import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { getValue } from 'AppUtils/objects';

import LuckyCageInstructionsComponent from "./lucky-cage/instructions/LuckyCageInstructionsComponent";
import BalloonsInstructionsComponent from "./balloons/instructions/BalloonsInstructionsComponent";
import LoaderPage from "../../../components/loader-page/LoaderPage";

import {
	gamesLoadFinishBonus, gamesLoadStartBonus, socketLoadInitChallenge, socketLoadStartChallenge,
	socketLoadStartConnection, socketLoadUpdateChallenge, socketLoadCloseConnection
} from "../store/actions";
import { gamesFinishBonus, gamesMsg, gamesSocket, gamesStartBonus, gamesStatusCode } from "../store/selectors";
import { authUuid } from "../../auth/store/selectors";
import { appGetInterfaceLang } from "../../../store/selectors";
import { uiRedirect } from "../../../store/actions";
import { userLoadNotificationsConsume } from "../../user/store/actions";


const stateToProps = state => ({
	gamesStartBonus: gamesStartBonus(state),
	gamesFinishBonus: gamesFinishBonus(state),
	gamesMsg: gamesMsg(state),
	gamesSocket: gamesSocket(state),
	gamesStatusCode: gamesStatusCode(state),
	authUuid: authUuid(state),
	appGetInterfaceLang: appGetInterfaceLang(state)
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	gamesLoadStartBonus: (data) => dispatch(gamesLoadStartBonus(data)),
	gamesLoadFinishBonus: (data) => dispatch(gamesLoadFinishBonus(data)),
	socketLoadStartConnection: () => dispatch(socketLoadStartConnection()),
	socketLoadInitChallenge: (data) => dispatch(socketLoadInitChallenge(data)),
	socketLoadUpdateChallenge: (data) => dispatch(socketLoadUpdateChallenge(data)),
	socketLoadStartChallenge: (data) => dispatch(socketLoadStartChallenge(data)),
	socketLoadCloseConnection: (data) => dispatch(socketLoadCloseConnection(data)),
	userLoadNotificationsConsume: (payload) => dispatch(userLoadNotificationsConsume(payload)),
});

@connect(stateToProps, actionsToProps)
class BonusGames extends Component {

	constructor(props) {
		super(props);

		this.state = {
			playId: getValue(this.props, 'location.state.playId'),
			imagesLoaded: false,
			shakeSupport: true
		}

		if(!this.state.playId) {
			this.props.uiRedirect({
				pathname: '/'
			})
		}
	}

	handlePreloadImages = () => {
		this.setState({
			imagesLoaded: true
		})
	}

	//to delete
	static getDerivedStateFromProps(props, current_state) {
		if (current_state.playId !== getValue(props, 'location.state.playId')) {
			return {
				playId: getValue(props, 'location.state.playId'),
			}
		}
		return null
	}

	componentDidMount() {
		const notificationId = getValue(this.props, 'location.state.notificationId');

		if (this.state.playId) {
			this.props.gamesLoadStartBonus({ playId: this.state.playId, notificationId });
		}

		if(!("ondevicemotion" in window)){
			this.setState({
				shakeSupport: false
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const notificationId = getValue(this.props, 'location.state.notificationId');

		// handle error code 409
		if (notificationId && this.props.gamesStatusCode == 409 && this.props.gamesStatusCode != prevProps.gamesStatusCode) {
			this.props.userLoadNotificationsConsume({ notificationId, callback: () => this.props.uiRedirect({
				pathname: '/'
			}) });
		}


		if (prevState.playId !== this.state.playId && this.state.playId) {
			this.props.gamesLoadStartBonus({ playId: this.state.playId, notificationId });
		}

		if (prevProps.gamesStartBonus !== this.props.gamesStartBonus && this.props.gamesStartBonus) {
			this.setState({
				gamesStartBonus: this.props.gamesStartBonus
			})
			if (this.props.gamesStartBonus.bonus_game) {
				this.props.getTitle(this.props.gamesStartBonus.bonus_game.title[this.props.appGetInterfaceLang])
			}
		}
	}

	render() {
		const notificationId = getValue(this.props, 'location.state.notificationId');

		return (
		  <>
		  <LoaderPage isVisible={this.state.imagesLoaded && this.state.gamesStartBonus}/>
		  {
			  this.state.gamesStartBonus && this.state.gamesStartBonus.bonus_game ?
				(this.state.gamesStartBonus.bonus_game.type === 'lucky_cage' ?
				  <LuckyCageInstructionsComponent imagesLoaded={this.state.imagesLoaded}
												  handlePreloadImages={this.handlePreloadImages}
												  gamesStartBonus={this.state.gamesStartBonus}
												  playId={this.state.playId}
												  notificationId={notificationId}
												  lang={this.props.appGetInterfaceLang}
												  uiRedirect={this.props.uiRedirect}
												  shakeSupport={this.state.shakeSupport}
				  /> :
				  this.state.gamesStartBonus.bonus_game.type === 'balloons' ?
					<BalloonsInstructionsComponent imagesLoaded={this.state.imagesLoaded}
												   handlePreloadImages={this.handlePreloadImages}
												   gamesStartBonus={this.state.gamesStartBonus}
												   playId={this.state.playId}
												   notificationId={notificationId}
												   lang={this.props.appGetInterfaceLang}
												   uiRedirect={this.props.uiRedirect}
					/> : null) :
				null
		  }
		  </>
		)
	}
}

export default BonusGames;