import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';

import FooterComponent from "./FooterComponent";

import {userRepositoryPrizes} from "../../containers/user/store/selectors";
import {appGetInterfaceLang} from "../../store/selectors";

const stateToProps = state => ({
    userRepositoryPrizes: userRepositoryPrizes(state),
    lang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({
});

@connect(stateToProps, actionsToProps)
class FooterContainer extends Component {
    render() {
        return (
          <FooterComponent
            prize={this.props.userRepositoryPrizes?.list.length > 0 ? this.props.userRepositoryPrizes?.list.filter((prize) => prize.position === 1)[0] : false}
            lang={this.props.lang}
          />
        );
    }
}

export default FooterContainer;