import React, {Component} from 'react';

import PositionComponent from "./PositionComponent";

class PositionContainer extends Component {
    render() {
        return (
            <PositionComponent position={this.props.position}/>
        );
    }
}

export default PositionContainer;