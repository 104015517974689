import { createSelector } from 'reselect';

const appGetStatus = state => state.app.status;
const appGetStatusCode = state => state.app.statusCode;
const appGetConfig = state => state.app.config;
const appGetTexts = state => state.app.texts;
const appGetLanguages = state => state.app.languages;
const appGetInterfaceLang = state => state.app.interfaceLang;

const uiGetSection = state => state.ui.section;
const uiGetSubsection = state => state.ui.subsection;
const uiGetMenu = state => state.ui.menu;
const uiGetModal = state => state.ui.modal;
const uiGetFooter = state => state.ui.footer;
const uiGetInfo = state => state.ui.info;

export {
    appGetStatus,
	appGetStatusCode,
	appGetConfig,
	appGetTexts,
	appGetLanguages,
	appGetInterfaceLang,
	uiGetSection,
	uiGetSubsection,
	uiGetMenu,
	uiGetModal,
	uiGetFooter,
	uiGetInfo,
};
