import React from 'react';
import clsx from 'clsx';
import getText from 'AppUtils/language';
import WrapperGame from '../../../subcomponents/wrapper-game/WrapperGame';
import TimerComponent from "../../../../../components/timer/Timer";
import InstructionsGame from "../../../subcomponents/instructions-game/InstructionsGame";
import QuizArea from "./subcomponents/QuizArea";
import ProgressBar from "../../../../../components/progress-bar/ProgressBar";

import './Quiz.scss';

const QuizComponent = (props) => {
	return (
	  <div className="quiz-component content-wrapper">
		  <WrapperGame>
			  <TimerComponent timeLeft={props.returnTimeLeft} />

			  <QuizArea handleClick={props.handleClick} dataSet={props.dataSet[props.current]} answerChoice={props.answerChoice} />

			  <ProgressBar steps={[...props.answers]} progress={((props.totalAnswered/props.answers.length) * 100).toFixed(3)} total={props.totalAnswered} />
		  </WrapperGame>

		  <InstructionsGame
			lineFirst={getText('Strike the 3 answers that are wrong')}
		  />
	  </div>
	);
};

export default QuizComponent;