import React from 'react';

import './File.scss';

const File = (props) => {
	return (
	  <div className="input-file">
		  <input
			id={props.id}
			name={props.name}
			type={props.type}
			placeholder={props.placeholder}
			required={props.required}
			value={props.value}
			onChange={(event) => props.onChange({event, blur: false})}
			onBlur={(event) => props.onChange({event, blur: true})}
			disabled={props.disabled ? props.disabled : false}
		  />
		  <label htmlFor={props.name}>{props.value ? props.value.split(/(\\|\/)/g).pop() : props.placeholder}</label>
	  </div>
	);
};

export default File;