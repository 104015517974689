import React from 'react';
import getText from '../../utils/language';

import Button from "../button/Button";

import './FourZeroFour.scss';

const FourZeroFourComponent = () => {
	return (
	  <div className="four-zero-four-page">
		  <div className="content">
			  <h1>404</h1>
			  <p>{getText('Page not found.')}</p>
			  <Button link="/" title={getText("Homepage")} slim={true}/>
		  </div>
	  </div>
	);
};

export default FourZeroFourComponent;