import * as GAMES from './types';
import ls from 'local-storage';

const defaultState = {
	section: 'games',
	msg: null,
	statusCode: null,
	startBonus: null,
	finishBonus: null,
	notificationId: null,
	socket: {
		status: null,
		data: null,
		call: '',
		error: null,
		loading: false,
		gameFinished: false,
	}
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {
		case GAMES.SOCKET_LOAD_INIT_CHALLENGE: {
			return {
				socket: {
					...state.socket,
					call: 'init_challenge',
					loading: true,
					data: null,
				}
			};
		}
		case GAMES.SOCKET_SET_INIT_CHALLENGE: {
			const { data = {}, error, status } = action.payload;

			if (data.challengePlay) {
				// data.challengePlay.category_code = "season";
				//
				// data.challengePlay.category_title = {
				// 	"en": "Season (winter/summer)"
				// };
			}

			return {
				socket: {
					...state.socket,
					status,
					data,
					error,
					loading: false,
				}
			};
		}
		case GAMES.SOCKET_LOAD_CONNECT: {
			return {
				socket: {
					...state.socket,
					call: 'connect',
					loading: true,
				}
			};
		}
		case GAMES.SOCKET_SET_CONNECT: {
			const { status } = action.payload;

			return {
				socket: {
					...state.socket,
					status,
					loading: false,
				}
			};
		}
		case GAMES.SOCKET_LOAD_DISCONNECT: {
			return {
				socket: {
					...state.socket,
					call: 'disconnect',
					loading: true,
				}
			};
		}
		case GAMES.SOCKET_SET_DISCONNECT: {
			const { status } = action.payload;

			return {
				socket: {
				  	...defaultState.socket,
					status,
					loading: false,
				}
			};
		}
		case GAMES.SOCKET_LOAD_START_CHALLENGE: {
			return {
				socket: {
					...state.socket,
					call: 'start_challenge',
					loading: true,
				}
			};
		}
		case GAMES.SOCKET_SET_START_CHALLENGE: {
			const { error, status } = action.payload;

			return {
				socket: {
					...state.socket,
					status,
					error,
					loading: false,
				}
			};
		}
		case GAMES.SOCKET_LOAD_UPDATE_CHALLENGE: {
			return {
				socket: {
					...state.socket,
					call: 'update_challenge',
					loading: true,
				}
			};
		}
		case GAMES.SOCKET_SET_UPDATE_CHALLENGE: {
			const { data = {}, error, status } = action.payload;

			if (data.challengePlay) {
				// data.challengePlay.category_code = "season";
				//
				// data.challengePlay.category_title = {
				// 	"en": "Season (winter/summer)"
				// };
			}

			return {
				socket: {
					...state.socket,
					status,
					data,
					error,
					loading: false,
				}
			};
		}
		case GAMES.SOCKET_LOAD_FINISH_CHALLENGE: {
			return {
				socket: {
					...state.socket,
					call: 'finish_challenge',
					loading: true,
				}
			};
		}
		case GAMES.SOCKET_SET_FINISH_CHALLENGE: {
			const { data = {}, error, status } = action.payload;

			return {
				socket: {
					...state.socket,
					status,
					data,
					error,
					loading: false,
					gameFinished: error ? false : true,
				}
			};
		}
		case GAMES.GAMES_SET_START_BONUS: {
			const { startBonus, msg, statusCode, notificationId = '' } = action.payload;

			return {
				...state,
				startBonus,
				msg,
				statusCode,
				notificationId,
			};
		}
		case GAMES.GAMES_SET_FINISH_BONUS: {
			const { finishBonus, msg, statusCode } = action.payload;

			return {
				...state,
				finishBonus,
				msg,
				statusCode,
			};
		}
		default:
			return state;
	}
}
