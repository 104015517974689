const LOAD_MSG = 'LOAD_MSG';
const SET_MSG = 'SET_MSG';

const USER_LOAD_INFO = 'USER_LOAD_INFO';
const USER_SET_INFO = 'USER_SET_INFO';

const USER_LOAD_UPDATE_INFO = 'USER_LOAD_UPDATE_INFO';
const USER_SET_UPDATE_INFO = 'USER_SET_UPDATE_INFO';

const USER_LOAD_POINTS = 'USER_LOAD_POINTS';
const USER_SET_POINTS = 'USER_SET_POINTS';

const USER_LOAD_CREDITS = 'USER_LOAD_CREDITS';
const USER_SET_CREDITS = 'USER_SET_CREDITS';

const USER_LOAD_BUY_CREDITS = 'USER_LOAD_BUY_CREDITS';
const USER_SET_BUY_CREDITS = 'USER_SET_BUY_CREDITS';

const USER_LOAD_LEADER_BOARD = 'USER_LOAD_LEADER_BOARD';
const USER_SET_LEADER_BOARD = 'USER_SET_LEADER_BOARD';

const USER_LOAD_EVENT = 'USER_LOAD_EVENT';
const USER_SET_EVENT = 'USER_SET_EVENT';

const USER_LOAD_GUEST_EVENT = 'USER_LOAD_GUEST_EVENT';
const USER_SET_GUEST_EVENT = 'USER_SET_GUEST_EVENT';

const USER_LOAD_LOGIN_WITH_TOKEN = 'USER_LOAD_LOGIN_WITH_TOKEN';
const USER_SET_LOGIN_WITH_TOKEN = 'USER_SET_LOGIN_WITH_TOKEN';

const USER_LOAD_UNSUBSCRIBE = 'USER_LOAD_UNSUBSCRIBE';
const USER_SET_UNSUBSCRIBE = 'USER_SET_UNSUBSCRIBE';

const USER_LOAD_GENERATE = 'USER_LOAD_GENERATE';
const USER_SET_GENERATE = 'USER_SET_GENERATE';

const USER_LOAD_SUBSCRIBE_DIRECT = 'USER_LOAD_SUBSCRIBE_DIRECT';
const USER_SET_SUBSCRIBE_DIRECT = 'USER_SET_SUBSCRIBE_DIRECT';

const USER_REPOSITORY_LOAD_ACHIEVEMENTS = 'USER_REPOSITORY_LOAD_ACHIEVEMENTS';
const USER_REPOSITORY_SET_ACHIEVEMENTS = 'USER_REPOSITORY_SET_ACHIEVEMENTS';

const USER_REPOSITORY_LOAD_SKILLS = 'USER_REPOSITORY_LOAD_SKILLS';
const USER_REPOSITORY_SET_SKILLS = 'USER_REPOSITORY_SET_SKILLS';

const USER_REPOSITORY_LOAD_BANNERS = 'USER_REPOSITORY_LOAD_BANNERS';
const USER_REPOSITORY_SET_BANNERS = 'USER_REPOSITORY_SET_BANNERS';

const USER_REPOSITORY_LOAD_CREDIT_PACKAGES = 'USER_REPOSITORY_LOAD_CREDIT_PACKAGES';
const USER_REPOSITORY_SET_CREDIT_PACKAGES = 'USER_REPOSITORY_SET_CREDIT_PACKAGES';

const USER_REPOSITORY_LOAD_PRIZES = 'USER_REPOSITORY_LOAD_PRIZES';
const USER_REPOSITORY_SET_PRIZES = 'USER_REPOSITORY_SET_PRIZES';

const USER_REPOSITORY_LOAD_MAP = 'USER_REPOSITORY_LOAD_MAP';
const USER_REPOSITORY_SET_MAP = 'USER_REPOSITORY_SET_MAP';

const USER_REPOSITORY_LOAD_AVATARS = 'USER_REPOSITORY_LOAD_AVATARS';
const USER_REPOSITORY_SET_AVATARS = 'USER_REPOSITORY_SET_AVATARS';

const USER_REPOSITORY_LOAD_STEPS = 'USER_REPOSITORY_LOAD_STEPS';
const USER_REPOSITORY_SET_STEPS = 'USER_REPOSITORY_SET_STEPS';

const USER_LOAD_NOTIFICATIONS = 'USER_LOAD_NOTIFICATIONS';
const USER_SET_NOTIFICATIONS = 'USER_SET_NOTIFICATIONS';

const USER_LOAD_NOTIFICATIONS_CONSUME = 'USER_LOAD_NOTIFICATIONS_CONSUME';
const USER_SET_NOTIFICATIONS_CONSUME = 'USER_SET_NOTIFICATIONS_CONSUME';

const USER_LOAD_UPDATE_PROFILE = 'USER_LOAD_UPDATE_PROFILE';
const USER_SET_UPDATE_PROFILE = 'USER_SET_UPDATE_PROFILE';

const USER_LOAD_UPDATE_ONBOARDING = 'USER_LOAD_UPDATE_ONBOARDING';

export {
	LOAD_MSG,
	SET_MSG,
	USER_LOAD_INFO,
	USER_SET_INFO,
	USER_LOAD_UPDATE_INFO,
	USER_SET_UPDATE_INFO,
	USER_LOAD_POINTS,
	USER_SET_POINTS,
	USER_LOAD_CREDITS,
	USER_SET_CREDITS,
	USER_LOAD_BUY_CREDITS,
	USER_SET_BUY_CREDITS,
	USER_LOAD_EVENT,
	USER_SET_EVENT,
	USER_LOAD_GUEST_EVENT,
	USER_SET_GUEST_EVENT,
	USER_LOAD_LOGIN_WITH_TOKEN,
	USER_SET_LOGIN_WITH_TOKEN,
	USER_LOAD_LEADER_BOARD,
	USER_SET_LEADER_BOARD,
	USER_LOAD_UNSUBSCRIBE,
	USER_SET_UNSUBSCRIBE,
	USER_LOAD_GENERATE,
    USER_SET_GENERATE,
    USER_LOAD_SUBSCRIBE_DIRECT,
    USER_SET_SUBSCRIBE_DIRECT,
	USER_REPOSITORY_LOAD_ACHIEVEMENTS,
    USER_REPOSITORY_SET_ACHIEVEMENTS,
    USER_REPOSITORY_LOAD_SKILLS,
    USER_REPOSITORY_SET_SKILLS,
	USER_REPOSITORY_LOAD_BANNERS,
    USER_REPOSITORY_SET_BANNERS,
	USER_REPOSITORY_LOAD_CREDIT_PACKAGES,
	USER_REPOSITORY_SET_CREDIT_PACKAGES,
	USER_REPOSITORY_LOAD_PRIZES,
	USER_REPOSITORY_SET_PRIZES,
	USER_REPOSITORY_LOAD_AVATARS,
	USER_REPOSITORY_SET_AVATARS,
	USER_REPOSITORY_LOAD_MAP,
    USER_REPOSITORY_SET_MAP,
	USER_REPOSITORY_LOAD_STEPS,
	USER_REPOSITORY_SET_STEPS,
	USER_LOAD_NOTIFICATIONS,
	USER_SET_NOTIFICATIONS,
	USER_LOAD_NOTIFICATIONS_CONSUME,
	USER_SET_NOTIFICATIONS_CONSUME,
	USER_LOAD_UPDATE_PROFILE,
	USER_SET_UPDATE_PROFILE,
	USER_LOAD_UPDATE_ONBOARDING,
};
