import React from 'react';
import {Link} from "react-router-dom";
import clsx from "clsx";

import './Button.scss';

const Button = (props) => {
	if(props.link) {
		return (
		  <Link to={props.link} className={clsx('btn', props.slim && 'btn-slim', props.disabled && 'disabled')} onClick={(e) => {
		  	if(props.onClick) {
		  		e.preventDefault();
		  		props.onClick();
			}
		  }}>
			  {props.title}
		  </Link>
		);
	} else {
		return (
		  <div {...props} className={clsx('btn', props.slim && 'btn-slim', props.disabled && 'disabled')}>
			  {props.title}
		  </div>
		);
	}
};

export default Button;