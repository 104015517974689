import React from 'react';

import LoaderPage from "../../../../components/loader-page/LoaderPage";
import SwipeItInstructionsContainer from "../subcomponents/swipe-it/instructions/SwipeItInstructionsContainer";
import LineWordsInstructionsContainer from "../subcomponents/line-words/instructions/LineWordsInstructionsContainer";
import ConnectWordsInstructionsContainer from "../subcomponents/connect-words/instructions/ConnectWordsInstructionsContainer";
import WrongAnswersInstructionsContainer from "../subcomponents/wrong-answers/instructions/WrongAnswersInstructionsContainer";

const GameComponent = (props) => {
	return (
	  <>
		  <LoaderPage isVisible={props.imagesLoaded && props.challengePlay} />
		  {
			  props.gameType === 'swipeIt' ?
				<SwipeItInstructionsContainer {...props} />
				:
				props.gameType === 'lineUp' ?
				  <LineWordsInstructionsContainer {...props} />
				  :
				  props.gameType === 'matchIt' ?
					<ConnectWordsInstructionsContainer {...props} />
					:
					props.gameType === 'top3' ?
					  <WrongAnswersInstructionsContainer {...props} />
					: null
		  }
	  </>
	);
};

export default GameComponent;