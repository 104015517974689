import React from 'react';
import getText from 'AppUtils/language';
import { motion } from "framer-motion";
import ReactFitText from "react-fittext";

import InstructionsGame from "../../subcomponents/instructions-game/InstructionsGame";

import videoBallons from './video/balloons_video.mp4';

import './Balloons.scss';
import Button from "../../../../components/button/Button";
import LoaderPage from "../../../../components/loader-page/LoaderPage";
import congrats from "../img/congrats.png";

const bonusVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.2,
		}
	}
}

const instructionsVariants = {
	hidden: {
		opacity: 0,
		transition: {
			type: 'tween',
			duration: 0.4,
			delay: 0.4
		}
	},
	visible: {
		opacity: 1,
	}
}

const buttonVariants = {
	hidden: {
		y: '100vh'
	},
	visible: {
		y: 0,
		transition: {
			type: 'tween',
			duration: 0.6,
			delay: 0.6
		}
	}
}

const congratsVariants = {
	hidden: {
		opacity: 0
	},
	visible: {
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.4,
			delay: 1.1
		}
	}
}

const prizeTextVariants = {
	hidden: {
		opacity: 0
	},
	visible: {
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.4,
			delay: 0.9
		}
	}
}

const prizeVariants = {
	hidden: {
		y: '10vh',
		opacity: 0,
		scale: 0.2
	},
	visible: {
		y: 0,
		scale: 1,
		opacity: 1,
		transition: {
			type: 'tween',
			// duration: 0.3,
			// delay: 0.8,
			opacity: {
				delay: 0.8,
				duration: 0.1,
			},
			scale: {
				delay: 1.2,
				duration: 0.3,
			},
			y: {
				delay: 1.2,
				duration: 0.3,
			}
		}
	}
}

const BalloonsComponent = (props) => {
	return (
	  <>
		  <LoaderPage isVisible={props.gamesStartBonus}/>

		  {props.gamesStartBonus && (
			<motion.div
			  className="bonus-pick-component content-wrapper"
			  variants={bonusVariants}
			  initial="hidden"
			  animate="visible"
			>

				<div className="prize">
					<motion.div
					  className="congrats"
					  variants={congratsVariants}
					  initial="hidden"
					  animate={props.showPrize ? 'visible' : 'hidden'}
					>
						<div className="text">
							<ReactFitText>
								<p>Congrats!</p>
							</ReactFitText>
						</div>
						<img src={congrats} alt=""/>
					</motion.div>
					<motion.span
					  variants={prizeTextVariants}
					  initial="hidden"
					  animate={props.showPrize ? 'visible' : 'hidden'}
					>{getText('You have won')}:
					</motion.span>
					<motion.p
					  variants={prizeVariants}
					  initial="hidden"
					  animate={props.showPrize ? 'visible' : 'hidden'}
					>
						{props.gamesStartBonus.prize_type === 'points' ?
						  `${props.gamesStartBonus.points} ${getText('points xp')}`
						  :
						  (props.gamesStartBonus.prize ? props.gamesStartBonus.prize.name[props.lang] : null)
						}
					</motion.p>
				</div>

				<div className="video-wrapper">
					<div className="video-inner">
						<div className="balloon-item balloon-1" onClick={() => props.handlePick(1)}></div>
						<div className="balloon-item balloon-2" onClick={() => props.handlePick(2)}></div>
						<div className="balloon-item balloon-3" onClick={() => props.handlePick(3)}></div>
						<div className="balloon-item balloon-4" onClick={() => props.handlePick(4)}></div>
						<div className="balloon-item balloon-5" onClick={() => props.handlePick(5)}></div>
						<div className="balloon-item balloon-6" onClick={() => props.handlePick(6)}></div>

						<video controls={false} autoPlay={true} ref={props.handleVideoMounted} className="video-ballons"
							   playsInline>
							<source src={videoBallons} type="video/mp4"/>
							{getText('Your browser does not support the video tag.')}
						</video>
					</div>
				</div>
				<motion.div
				  variants={instructionsVariants}
				  initial="visible"
				  animate={props.showPrize ? 'hidden' : ''}
				>
					<InstructionsGame
					  lineFirst={props.gamesStartBonus.bonus_game ? props.gamesStartBonus.bonus_game.hint[props.lang] : ''}
					/>
				</motion.div>

				<motion.div
				  className="btn-redeem"
				  variants={buttonVariants}
				  initial="hidden"
				  animate={props.showPrize ? 'visible' : 'hidden'}
				  onClick={() => props.handleRedeem()}
				>
					<Button title={getText('REDEEM')}/>
				</motion.div>
			</motion.div>
		  )}
	  </>
	);
};

export default BalloonsComponent;