export const getQueryVariable = function (query, variable, def = null) {
    const vars = getQueryVariables(query);

    if (vars[variable]) {
    	return vars[variable];
	}

    return def;
};

export const getQueryVariables = function (url) {
	var queryString = url.split('?')[1];
	var obj = {};

	if (queryString) {
		queryString = queryString.split('#')[0];
		var arr = queryString.split('&');

		for (var i = 0; i < arr.length; i++) {
			var a = arr[i].split('=');
			var paramName = a[0];
			var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
			paramName = paramName.toLowerCase();
			if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

			// if the paramName ends with square brackets
			if (paramName.match(/\[(\d+)?\]$/)) {
				var key = paramName.replace(/\[(\d+)?\]/, '');
				if (!obj[key]) obj[key] = [];

				if (paramName.match(/\[\d+\]$/)) {
					var index = /\[(\d+)\]/.exec(paramName)[1];
					obj[key][index] = paramValue;
				} else {
					obj[key].push(paramValue);
				}
			} else {
				// string
				if (!obj[paramName]) {
					obj[paramName] = paramValue;
				} else if (obj[paramName] && typeof obj[paramName] === 'string'){
					obj[paramName] = [obj[paramName]];
					obj[paramName].push(paramValue);
				} else {
					obj[paramName].push(paramValue);
				}
			}
		}
	}

	return obj;
};

export const joinQueryVariables = function (variables) {
    const pairs = [];
    Object.keys(variables).map(key => {
        pairs.push(`${key}=${variables[key]}`);
    });
    return pairs.join('&');
};

export const removeQueryVariable = function (queryString, variable) {
    const variables = getQueryVariables(queryString);
    if (variables[variable]) delete variables[variable];
    if (Object.keys(variables).length) { return `?${joinQueryVariables(variables)}`; }
};

export const compressUrlParams = function (params = {}) {
    const keys = Object.keys(params);
    if (keys.length) { return window.btoa(JSON.stringify(params)); }
    return '';
};

export const decompressUrlParams = function (code) {
    if (!code) return {};
    try {
        return JSON.parse(window.atob(code));
    } catch (e) {
        return {};
    }
};

const _parseUrlElem = document.createElement('a');
export const parseUrl = (string) => {
    _parseUrlElem.setAttribute('href', string);
    const { host, hostname, pathname, port, protocol, search, hash } = _parseUrlElem;
    const origin = `${protocol}//${hostname}${port.length ? `:${port}` : ''}`;
    return { origin, host, hostname, pathname, port, protocol, search, hash };
};


const __lang_regex = /(\/it|\/fr|\/es|\/de|\/cn|\/tr)(\/.*)/img;
export const adjustToLink = (payload, basepath = null) => {
    let out;
    //Check to see if the payload is in fact string, and decompose it
    if (typeof payload === 'string') {
        if (payload == '#') {
            out = { hash: '#' };
        } else {
            const { pathname, search, hash } = parseUrl(payload);
            out = { pathname, search, hash };
        }
    } else if (typeof payload === 'object') {
        out = { ...payload };
    } else {
        throw new Error('Invalid payload data');
    }

    //Make sure we only have one # at the begining
    if (out.hash && out.hash.indexOf('#') !== 0) {
        out.hash = `#${out.hash}`;
    }

    const options = out.options ? out.options : { keephash: true, keepsearch: true };

    if (!out.pathname) {
        //If the pathname is missing we need to recover it from the URL
        out.pathname = window.location.pathname;
        //If we search is missing, we need to recover it too
        if (!out.search) {
            //If we did not s
            if (options.keepsearch) {
                //Did we wanted to maintain the hash?
                out.search = window.location.search;
            }
        }
    }

    if (!out.search) {
        out.search = '';
    }

    if (!out.hash) {
        //The hash might need to be recovered
        if (options.keephash) {
            //Did we wanted to maintain the hash?
            out.hash = window.location.hash;
        }
    }

    if (out.pathname && out.pathname.indexOf('/') !== 0) {
        out.pathname = `/${out.pathname}`;
    }

    if (out.hash && out.hash.length == 1) {
        out.hash = '';
    }

    //Make sure we don't have /es|fr|ro ..etc in front
    if (out.pathname) {
        out.pathname = out.pathname.replace(__lang_regex, '$2');
    }

    if (basepath) {
        out.pathname = `${basepath}${out.pathname}`;
    }


    out.fullPath = `${out.pathname}${out.search}${out.hash}`;
    delete out.to;
    delete out.options;

    return out;
};

export const adjustUrlForLanguage = function (lang, url = null) {
	let noLangUrl = '';
    if (false) {
        const urlLanguageRegex = /(.*)(\/it|\/fr|\/es|\/de|\/cn|\/tr)(\/.*)/img;
        if (lang == 'en') {
            noLangUrl = window.location.href.replace(urlLanguageRegex, '$1$3');
            const urlObj = adjustToLink(noLangUrl);
            if (urlObj.search) { return `${urlObj.fullPath}&lang=en`; }
            return `${urlObj.fullPath}?lang=en`;
            return noLangUrl;
        }

        const langUrl = window.location.href.replace(urlLanguageRegex, `$1/${lang}$3`);
        return langUrl;
    }

        //it is english
    if (false) {
        const urlObj = adjustToLink(adjustToLink({}).pathname);
        if (urlObj.search) { return `${urlObj.fullPath}&lang=en`; }
        return `${urlObj.fullPath}?lang=en`;
        return noLangUrl;
    }

    return adjustToLink(adjustToLink({}).pathname, `/${lang}`).fullPath;
};
