import * as USER from './types';

const defaultState = {
	section: 'user',
	msg: null,
	statusCode: null,
	info: null,
	updateInfo: null,
	points: null,
	credits: null,
	buyCredits: null,
	leaderBoard: {
		list: [],
		isLoading: false,
	},
	repositoryAchievements: {
		list: [],
		isLoading: false,
	},
	repositorySkills: {
		list: [],
		isLoading: false,
	},
	repositoryBanners: {
		list: [],
		isLoading: false,
	},
	repositoryCreditPackages: {
		list: [],
		isLoading: false,
	},
	repositoryPrizes: {
		list: [],
		isLoading: false,
	},
	repositoryMap: {
		list: [],
		isLoading: false,
	},
	repositoryAvatars: {
		list: [],
		isLoading: false,
	},
	repositorySteps: {
		list: [],
		isLoading: false,
	},
	notifications: {
		list: [],
		isLoading: false,
	},
	notificationsConsume: {
		data: '',
		isLoading: false,
	},
	profileUpdate: {
		data: '',
		isLoading: false,
	},
	event: null,
	guestEvent: null,
	unsubscribe: null,
	generate: null,
	subscribeDirect: null,
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {
		case USER.USER_SET_INFO: {
			const { info, msg, statusCode } = action.payload;

			return {
				...state,
				info,
				msg,
				statusCode,
			};
		}
		case USER.USER_LOAD_UPDATE_INFO: {
			return {
				...state,
				msg: '',
				statusCode: '',
			};
		}
		case USER.USER_SET_UPDATE_INFO: {
			const { updateInfo, msg, statusCode } = action.payload;

			return {
				...state,
				updateInfo,
				msg,
				statusCode,
			};
		}
		case USER.USER_LOAD_UNSUBSCRIBE: {
			return {
				...state,
				unsubscribe: '',
				msg: '',
				statusCode: '',
			};
		}
		case USER.USER_SET_UNSUBSCRIBE: {
			const { unsubscribe, msg, statusCode } = action.payload;

			return {
				...state,
				unsubscribe,
				msg,
				statusCode,
			};
		}
		case USER.USER_SET_POINTS: {
			const { points, msg, statusCode } = action.payload;

			return {
				...state,
				points,
				msg,
				statusCode,
			};
		}
		case USER.USER_LOAD_EVENT: {
			return {
				...state,
				event: null,
			};
		}
		case USER.USER_SET_EVENT: {
			const { event, msg, statusCode } = action.payload;

			return {
				...state,
				event,
				msg,
				statusCode,
			};
		}
		case USER.USER_LOAD_GUEST_EVENT: {
			return {
				...state,
				guestEvent: null,
			};
		}
		case USER.USER_SET_GUEST_EVENT: {
			const { guestEvent, msg, statusCode } = action.payload;

			return {
				...state,
				guestEvent,
				msg,
				statusCode,
			};
		}
		case USER.USER_SET_CREDITS: {
			const { credits, msg, statusCode } = action.payload;

			return {
				...state,
				credits,
				msg,
				statusCode,
			};
		}
		case USER.USER_SET_BUY_CREDITS: {
			const { buyCredits, msg, statusCode } = action.payload;

			return {
				...state,
				buyCredits,
				msg,
				statusCode,
			};
		}
		case USER.USER_LOAD_LEADER_BOARD:
			return {
				...state,
				leaderBoard: {
					...state.leaderBoard,
					isLoading: true,
				},
				msg: '',
			};
		case USER.USER_SET_LEADER_BOARD: {
			const { list, msg, statusCode } = action.payload;

			return {
				...state,
				leaderBoard: {
					list,
					isLoading: false,
				},
				msg,
				statusCode,
			};
		}
		case USER.USER_REPOSITORY_LOAD_ACHIEVEMENTS:
			return {
				...state,
				repositoryAchievements: {
					...state.repositoryAchievements,
					isLoading: true,
				},
				msg: '',
			};
		case USER.USER_REPOSITORY_SET_ACHIEVEMENTS: {
			const { list, msg, statusCode } = action.payload;

			return {
				...state,
				repositoryAchievements: {
					list,
					isLoading: false,
				},
				msg,
				statusCode,
			};
		}
		case USER.USER_REPOSITORY_LOAD_SKILLS:
			return {
				...state,
				repositorySkills: {
					...state.repositorySkills,
					isLoading: true,
				},
				msg: '',
			};
		case USER.USER_REPOSITORY_SET_SKILLS: {
			const { list, msg, statusCode } = action.payload;

			return {
				...state,
				repositorySkills: {
					list,
					isLoading: false,
				},
				msg,
				statusCode,
			};
		}
		case USER.USER_SET_BUY_CREDITS: {
			const { buyCredits, msg, statusCode } = action.payload;

			return {
				...state,
				buyCredits,
				msg,
				statusCode,
			};
		}
		case USER.USER_SET_GENERATE: {
			const { generate, msg, statusCode } = action.payload;

			return {
				...state,
				generate,
				msg,
				statusCode,
			};
		}
		case USER.USER_SET_SUBSCRIBE_DIRECT: {
			const { subscribeDirect, msg, statusCode } = action.payload;

			return {
				...state,
				subscribeDirect,
				msg,
				statusCode,
			};
		}
		case USER.USER_REPOSITORY_LOAD_BANNERS:
			return {
				...state,
				repositoryBanners: {
					...state.repositoryBanners,
					isLoading: true,
				},
				msg: '',
			};
		case USER.USER_REPOSITORY_SET_BANNERS: {
			const { list, msg, statusCode } = action.payload;

			return {
				...state,
				repositoryBanners: {
					list,
					isLoading: false,
				},
				msg,
				statusCode,
			};
		}
		case USER.USER_REPOSITORY_LOAD_CREDIT_PACKAGES:
			return {
				...state,
				repositoryCreditPackages: {
					...state.repositoryCreditPackages,
					isLoading: true,
				},
				msg: '',
			};
		case USER.USER_REPOSITORY_SET_CREDIT_PACKAGES: {
			const { list, msg, statusCode } = action.payload;

			return {
				...state,
				repositoryCreditPackages: {
					list,
					isLoading: false,
				},
				msg,
				statusCode,
			};
		}
		case USER.USER_REPOSITORY_LOAD_PRIZES:
			return {
				...state,
				repositoryPrizes: {
					...state.repositoryPrizes,
					isLoading: true,
				},
				msg: '',
			};
		case USER.USER_REPOSITORY_SET_PRIZES: {
			const { list, msg, statusCode } = action.payload;

			return {
				...state,
				repositoryPrizes: {
					list,
					isLoading: false,
				},
				msg,
				statusCode,
			};
		}
		case USER.USER_REPOSITORY_LOAD_MAP:
			return {
				...state,
				repositoryMap: {
					...state.repositoryMap,
					isLoading: true,
				},
				msg: '',
			};
		case USER.USER_REPOSITORY_SET_MAP: {
			const { list, msg, statusCode } = action.payload;

			return {
				...state,
				repositoryMap: {
					list,
					isLoading: false,
				},
				msg,
				statusCode,
			};
		}
		case USER.USER_REPOSITORY_LOAD_AVATARS:
			return {
				...state,
				repositoryAvatars: {
					...state.repositoryAvatars,
					isLoading: true,
				},
				msg: '',
			};
		case USER.USER_REPOSITORY_SET_AVATARS: {
			const { list, msg, statusCode } = action.payload;

			return {
				...state,
				repositoryAvatars: {
					list,
					isLoading: false,
				},
				msg,
				statusCode,
			};
		}
		case USER.USER_REPOSITORY_LOAD_STEPS:
			return {
				...state,
				repositorySteps: {
					...state.repositorySteps,
					isLoading: true,
				},
				msg: '',
			};
		case USER.USER_REPOSITORY_SET_STEPS: {
			const { list, msg, statusCode } = action.payload;

			return {
				...state,
				repositorySteps: {
					list,
					isLoading: false,
				},
				msg,
				statusCode,
			};
		}
		case USER.USER_LOAD_NOTIFICATIONS:
			return {
				...state,
				notifications: {
					...state.notifications,
					isLoading: true,
				},
				msg: '',
			};
		case USER.USER_SET_NOTIFICATIONS: {
			const { list, msg, statusCode } = action.payload;

			return {
				...state,
				notifications: {
					list,
					isLoading: false,
				},
				msg,
				statusCode,
			};
		}
		case USER.USER_LOAD_NOTIFICATIONS_CONSUME:
			return {
				...state,
				notificationsConsume: {
					...state.notificationsConsume,
					isLoading: true,
				},
				msg: '',
			};
		case USER.USER_SET_NOTIFICATIONS_CONSUME: {
			const { data, msg, statusCode } = action.payload;

			return {
				...state,
				notificationsConsume: {
					data,
					isLoading: false,
				},
				msg,
				statusCode,
			};
		}
		case USER.USER_LOAD_UPDATE_PROFILE:
			return {
				...state,
				profileUpdate: {
					...state.profileUpdate,
					isLoading: true,
				},
				msg: '',
			};
		case USER.USER_SET_UPDATE_PROFILE: {
			const { data, msg, statusCode } = action.payload;

			return {
				...state,
				profileUpdate: {
					data,
					isLoading: false,
				},
				msg,
				statusCode,
			};
		}
		default:
			return state;
	}
}
