import { createAction } from 'redux-actions';
import * as GAMES from './types';

const gamesLoadStartBonus = createAction(GAMES.GAMES_LOAD_START_BONUS, data => data);
const gamesLoadFinishBonus = createAction(GAMES.GAMES_LOAD_FINISH_BONUS, data => data);

const socketLoadInitChallenge = createAction(GAMES.SOCKET_LOAD_INIT_CHALLENGE, data => data);
const socketLoadTimeoutConnection = createAction(GAMES.SOCKET_LOAD_TIMEOUT, data => data);
const socketLoadStartConnection = createAction(GAMES.SOCKET_LOAD_CONNECT);
const socketLoadCloseConnection = createAction(GAMES.SOCKET_LOAD_DISCONNECT);
const socketLoadStartChallenge = createAction(GAMES.SOCKET_LOAD_START_CHALLENGE);
const socketLoadUpdateChallenge = createAction(GAMES.SOCKET_LOAD_UPDATE_CHALLENGE, data => data);
const socketLoadFinishChallenge = createAction(GAMES.SOCKET_LOAD_FINISH_CHALLENGE);

export {
	gamesLoadStartBonus,
	gamesLoadFinishBonus,
	socketLoadInitChallenge,
	socketLoadStartConnection,
	socketLoadTimeoutConnection,
	socketLoadCloseConnection,
	socketLoadStartChallenge,
	socketLoadUpdateChallenge,
	socketLoadFinishChallenge,
};
