import React, {Component} from 'react';
import { getValue } from 'AppUtils/objects';
import connect from 'react-redux/es/connect/connect';
import isEqual from "react-fast-compare";
import XDate from 'AppUtils/xdate';

import AchievementsDetailsComponent from "./AchievementsDetailsComponent";
import { userInfo, userRepositoryAchievements } from "../../../user/store/selectors";
import { userRepositoryLoadAchievements } from "../../../user/store/actions";
import { appGetInterfaceLang } from "../../../../store/selectors";
import Layout from "../../../../layout/Layout";

const stateToProps = state => ({
	userRepositoryAchievements: userRepositoryAchievements(state),
	userInfo: userInfo(state),
	appGetInterfaceLang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({
	userRepositoryLoadAchievements: () => dispatch(userRepositoryLoadAchievements()),
});

@connect(stateToProps, actionsToProps)
class AchievementsDetailsContainer extends Component {

    state = {
        imagesLoaded: false,
		repositoryAchievements: [],
        id: '',
		filtersUi: {
			isLoading: false
		},
    };

    handlePreloadImages = () => {
        this.setState({
            imagesLoaded: true
        })
    }

    componentDidMount() {
		const { id } = getValue(this.props, 'match.params');

		this.setState({
            id
        });

		this.props.userRepositoryLoadAchievements();
    }

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { userRepositoryAchievements } = this.props;

		if (userRepositoryAchievements.isLoading == false && userRepositoryAchievements.list.length > 0 && (this.state.repositoryAchievements.length == 0 || !isEqual(userRepositoryAchievements.list, prevProps.userRepositoryAchievements.list))) {
			this.setState(prevState => ({
				repositoryAchievements: [...prevState.repositoryAchievements, ...userRepositoryAchievements.list],
				hasMore: userRepositoryAchievements.list.length > 0,
				filtersUi: {
					...prevState.filtersUi,
					isLoading: false
				},
			}));
		}
	}

    render() {
        const { repositoryAchievements, id, filtersUi } = this.state;

		let achievement = '';
		const userInfo  = getValue(this.props, 'userInfo.character');

		if (repositoryAchievements.length > 0) {
			achievement = repositoryAchievements.find(item => item.id == id);

			if (userInfo.achievements && repositoryAchievements.length > 0) {
				for (let i in userInfo.achievements) {
					if (achievement.id == userInfo.achievements[i].id) {
						achievement = { ...achievement, ...userInfo.achievements[i]};
					}
				}
			}
		}

        return (
          <Layout hasFooter={false} hasStats={false} hasSubheader={true} title={achievement && achievement.name[this.props.appGetInterfaceLang]} showMenu={this.props.showMenu} toggleMenu={this.props.toggleMenu}>
                <AchievementsDetailsComponent
                  imagesLoaded={this.state.imagesLoaded}
                  handlePreloadImages={this.handlePreloadImages}
                  repositoryAchievements={repositoryAchievements}
                  appGetInterfaceLang={this.props.appGetInterfaceLang}
                  achievement={achievement}
                />
          </Layout>
        );
    }
}

export default AchievementsDetailsContainer;