import React, {Component} from 'react';
import Timer from "react-compound-timer";

import QuizComponent from "./QuizComponent";
import logger from 'AppUtils/logging';

class QuizContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			current:0,
			dataSet:[],
			correct:0,
			incorrect:0,
			answerChoice: '',
			allChoices: {},
			timeFinished: 0,
			answers: [null, null, null, null],
			totalAnswered: 0
		}

		this.handleClick = this.handleClick.bind(this)

		this.props.timer.setCheckpoints([
			{
				time: 0,
				callback: () => {
					this.handleClick();
				},
			},
		]);
		this.props.timer.setTime(60 * 1000 + 500);
		this.props.timer.start();
	}

	componentDidMount() {
		logger.info('mount')
		this.setState({
			dataSet: [
				{
					id: 1,
					question: "Intrebare 1?",
					answers: ["answer 1", "answer 2", "answer 3", "answer 4", "answer 5", "answer 6"],
					touched: false,
					correct: 1,
				},
				{
					id: 2,
					question: "Intrebare 2?",
					answers: ["answer 1", "answer 2", "answer 3", "answer 4", "answer 5", "answer 6"],
					touched: false,
					correct: 1,
				},
				{
					id: 3,
					question: "Intrebare 3?",
					answers: ["answer 1", "answer 2", "answer 3", "answer 4", "answer 5", "answer 6"],
					touched: false,
					correct: 1,
				},
				{
					id: 4,
					question: "Intrebare 4?",
					answers: ["answer 1", "answer 2", "answer 3", "answer 4", "answer 5", "answer 6"],
					touched: false,
					correct: 1,
				}
			]
		})
	}

	handleClick(choice) {
		this.props.timer.pause();
		if (choice == this.state.dataSet[this.state.current].correct) {
			let newDataSet = [...this.state.dataSet];
			newDataSet[this.state.current].touched = true;

			let allChoices = {...this.state.allChoices};
			const currentId = newDataSet[this.state.current].id;

			allChoices[newDataSet[this.state.current].id] = newDataSet[this.state.current].answers[choice];

			let answers = [...this.state.answers];
			answers[this.state.current] = 1;

			this.setState({
				correct: this.state.correct + 1,
				answerChoice: choice,
				dataSet: newDataSet,
				allChoices,
				answers,
				totalAnswered: this.state.totalAnswered+1
			})
		} else {
			let newDataSet = [...this.state.dataSet];
			newDataSet[this.state.current].touched = true;

			let allChoices = {...this.state.allChoices};
			const currentId = newDataSet[this.state.current].id;

			allChoices[newDataSet[this.state.current].id] = newDataSet[this.state.current].answers[choice] ? newDataSet[this.state.current].answers[choice] : null;

			let answers = [...this.state.answers];
			answers[this.state.current] = 0;

			this.setState({
				incorrect: this.state.incorrect + 1,
				answerChoice: choice,
				dataSet: newDataSet,
				allChoices,
				answers,
				totalAnswered: this.state.totalAnswered+1
			})
		}

		if (this.state.current == this.state.dataSet.length - 1) {
			setTimeout(() => {
				this.handleNextQuestion(parseInt(this.props.timer.getTime()/1000));
			}, 1500);
		} else {
			setTimeout(() => {
				this.handleNextQuestion(parseInt(this.props.timer.getTime()/1000));
			}, 1500);

		}
	}

	handleNextQuestion = (timeRemaining) => {
		let isLast = this.state.current == this.state.dataSet.length - 1;
		logger.info(timeRemaining)

		this.setState({
			current: !isLast ? this.state.current + 1 : this.state.current,
			answerChoice: isLast ? this.state.answerChoice : "",
			timeFinished: timeRemaining ? this.state.timeFinished + (60 - timeRemaining) : this.state.timeFinished + 60,
		}, () => {
			this.props.timer.reset();
			if(isLast) {
				this.props.timer.pause();
				// this.props.uiRedirect({
				// 	pathname: '/result',
				// 	state: {
				// 		gamePlayId: parseInt(this.props.playStartGame.id),
				// 		time: this.state.timeFinished,
				// 		answers: this.state.allChoices,
				// 	}
				// })
			} else {
				this.props.timer.start();
			}
		})
	}

	returnTimeLeft = () => {
		return (
		  <>
			  <Timer.Minutes formatValue={value => ("0" + value).slice(-2)} />:<Timer.Seconds formatValue={value => ("0" + value).slice(-2)} />
		  </>
		);
	}

	render() {
		if(!this.state.dataSet.length) {
			return null;
		}

		return (
		  <QuizComponent
			{...this.state}
			returnTimeLeft={this.returnTimeLeft}
			handleClick={this.handleClick}
		  />
		);
	}
}

const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
  <Timer {...timerProps}>
	  {timerRenderProps =>
		<WrappedComponent {...wrappedComponentProps} timer={timerRenderProps} />}
  </Timer>
);

const TimerHOC = withTimer({
	direction: 'backward',
	initialTime: 60000,
	startImmediately: false,
})(QuizContainer);

export default TimerHOC;