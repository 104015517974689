import React from 'react';
import { motion, AnimatePresence } from "framer-motion"

import { getValue } from 'AppUtils/objects';
import getText from 'AppUtils/language';
import CountUp from 'react-countup';
// import { useCountUp } from 'react-countup';
import CountTo from 'react-count-to';


import bgHeader from './img/bg-header.png';
import logoHeader from './img/logo-header.svg';
import iconHamburger from './img/icon-hamburger.svg';
import bgTickets from './img/bg-tickets.png';
import bgLevel from './img/bg-level.png'
import iconTicket from './img/icon-ticket.png';
import ticket from './img/ticket.png';

import SubheaderContainer from "./subcomponents/subheader/SubheaderContainer";

import './Header.scss';
import {Link} from "react-router-dom";

const headerVariants = {
	hidden: {
		y: '-100vh'
	},
	visible: {
		y: 0,
		transition: {
			type: 'tween',
			duration: 0.6
		}
	}
}

const lvlVariants = {
	hidden: {
		scale: 1.4,
	},
	visible: {
		scale: 1,
		transition: {
			type: 'tween',
			duration: 0.5
		}
	},
}

const ticketVariants = {
	hidden: {
		x: -20,
		opacity: 0,
		scale: 1.2,
	},
	visible: {
		x: 0,
		opacity: 1,
		scale: 1,
		transition: {
			type: 'tween',
			duration: 0.3
		}
	},
}

const HeaderComponent = ({ toggleMenu, hasSubheader, hasStats, hasHint, title, backLink, userInfo, oldPoints, oldLevelPercentage, levelPercentage, setValue, lvlUp, setLvlUp, handleHint, gameType, isHint, hideBack, gameReady}) => {
	const userInfoCh = getValue(userInfo, 'character', '');
	const userInfoEl = getValue(userInfo, 'eligibility', '');
	const credits = getValue(userInfoEl, 'credits.purchased', 0) + getValue(userInfoEl, 'credits.subscription', 0);

	if(levelPercentage < oldLevelPercentage) {
		setValue({
			levelPercentage: 100
		});
		setLvlUp();
	}

	const handleComplete = () => {
		if(levelPercentage === 100 && userInfo.character.level.percentage !== 100) {
			setValue({
				oldLevelPercentage: 0,
				levelPercentage: userInfo.character.level.percentage
			})
		}
	}

	const fn = value => <span className="progress-no" style={{width: `${value}%`}}></span>;

	const renderTickets = () => {
		let tickets = [];
		for(let i = 0; i < 5; i++) {
			tickets.push(
			  <AnimatePresence>
				  {credits > i && (
					<motion.img
					  src={ticket}
					  alt=""
					  variants={ticketVariants}
					  initial="hidden"
					  animate="visible"
					  exit="hidden"
					/>
				  )}
			  </AnimatePresence>
			)
		}
		return tickets;
	}

	return (
	  <motion.div
		className="header"
		variants={headerVariants}
		initial="hidden"
		animate="visible"
	  >
		  <div className="header-top">
			  <img src={bgHeader} alt="" className="bg-header"/>

			  <div className="hamburger-menu">
				  <img
					onClick={toggleMenu}
					src={iconHamburger}
					alt=""
					className='icon-hamburger'/>
			  </div>
			  <div className="logo">
				  <img src={logoHeader} alt=""/>
			  </div>
			  <Link to="/profile" className="user-avatar">
				  <img src={getValue(userInfoCh, 'avatar')} alt=""/>
			  </Link>
		  </div>
		  { hasStats && <div className="header-bottom">
			  <div className="tickets">
				  <img src={bgTickets} alt=""/>
				  <div className="info">
					  <div className="number-container">
						  <img src={iconTicket} alt="" className="icon-ticket"/>
						  <p>{getText('Tickets')}</p>
						  <motion.span
							key={credits}
							variants={lvlVariants}
							initial="hidden"
							animate="visible"
						  >{credits}</motion.span>
					  </div>

					  <div className="list-tickets">
						  {renderTickets()}
					  </div>
				  </div>
			  </div>
			  <div className="level">
				  <img src={bgLevel} alt=""/>
				  <div className="info">
					  <div className="number-container">
						  <p>{getText('LEVEL')}</p>
						  <motion.span
							key={lvlUp}
							variants={lvlVariants}
							initial="hidden"
							animate="visible"
						  >{getValue(userInfoCh, 'level.current', 0) ? userInfoCh.level.current : 0}</motion.span>
					  </div>

					  <div className="level-progress">
						  <p><CountUp delay={0.8} start={oldPoints ? oldPoints : getValue(userInfoCh, 'points', 0)} end={getValue(userInfoCh, 'points', 0)} duration={0.6} /> XP</p>
						  <div className="progressbar">
							  <div className="progressbar-bg"></div>
							  <CountTo from={oldLevelPercentage !== null ? oldLevelPercentage : levelPercentage} to={levelPercentage} speed={500} onComplete={handleComplete}>{fn}</CountTo>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
		  }

		  {hasSubheader &&
		  	<SubheaderContainer
				title={title}
				hasHint={hasHint}
				backLink={backLink}
				handleHint={handleHint}
				skills={userInfoCh.skills.items[gameType]}
				isHint={isHint}
				hideBack={hideBack}
				gameReady={gameReady}
			/>
		  }

		  <div className="bg-gradient"></div>
	  </motion.div>
	);
};

export default HeaderComponent;