import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import {authStatus} from "./store/selectors";
import { uiRedirect, uiToggleModal } from "../../store/actions";
import getText, { HtmlParser } from "AppUtils/language"

const stateToProps = state => ({
	authStatus: authStatus(state)
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
});

@connect(stateToProps, actionsToProps)
class CheckAuth extends Component {
	state = {
		hasError: false
	};

	componentDidCatch(error, info) {
		if (process.env.NODE_ENV === 'production') {
			this.setState({
				hasError: true
			});

			this.handleJsError();
		}
	}

	openModal = (title, message, button, buttonText, button2, button2Text, restrictClose, callback, callback2) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, restrictClose, callback });
	};

	handleJsError = () => {
		this.openModal(getText('Error'), getText('There seems to be a problem. Please try again.'), true, getText('Retry'), false, false, true, () => {
			location.reload();
		});
	}

	render() {
		if (this.state.hasError) {
			return null;
		}

		if(this.props.authStatus === 'guest') {
			if(this.props.restricted) {
				this.props.uiRedirect({
					pathname: '/signin',
					state: { from: window.location.pathname }
				})

				return null;
			}
		} else if(this.props.authStatus === 'valid' || this.props.authStatus === 'restricted') {
			if(!this.props.restricted) {
				this.props.uiRedirect({
					pathname: '/'
				})

				return null;
			}
		}

		return React.cloneElement(this.props.children, {});
	}
}

export default CheckAuth;