import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import './utils/polyfill/';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureAppStore from 'AppStore/configureStore';
import { render as reactDomRender } from 'react-dom';
import App from './App';

const appStore = configureAppStore();

const render = Component => reactDomRender(
  <Provider store={appStore.store}>
      <ConnectedRouter history={appStore.history}>
          <Component/>
      </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

render(App);