import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import Observable from 'Observable';


import PromptComponent from "./PromptComponent";

import { apiPost } from 'AppUtils/api';
import logger from 'AppUtils/logging';

import {validateSubmit} from "../../../../../../utils/form";
import { uiRedirect, uiToggleModal } from "../../../../../../store/actions";
import { authStatus, authUuid } from "../../../../store/selectors";
import getText from 'AppUtils/language';
import { authSet } from "../../../../store/actions";
import { userLoadEvent, userLoadGuestEvent, userLoadInfo } from "../../../../../user/store/actions";
import { getValue } from 'AppUtils/objects';

const stateToProps = state => ({
	authUuid: authUuid(state),
	authStatus: authStatus(state)
});

const actionsToProps = dispatch => ({
	authSet: (auth) => dispatch(authSet(auth)),
	userLoadInfo: (data) => dispatch(userLoadInfo(data)),
	userLoadEvent: (data) => dispatch(userLoadEvent(data)),
	userLoadGuestEvent: (data) => dispatch(userLoadGuestEvent(data)),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	uiRedirect: (url) => dispatch(uiRedirect(url)),
});

@connect(stateToProps, actionsToProps)
class PromptContainer extends Component {

	state = {
		formData: {
			prefix: {
				element: 'input',
				value: `+${this.props.prefix}`,
				config: {
					id: 'prefix',
					name: 'prefix',
					type: 'tel',
					placeholder: getText('Prefix'),
				},
				validation: {
					required: false,
					minlength: 0,
					numeric: true
				},
				valid: false,
				touched: false,
				validationMessage: ''
			},
			phone: {
				element: 'input',
				value: '',
				config: {
					id: 'phone',
					name: 'phone',
					type: 'tel',
					placeholder: getText('Phone'),
				},
				validation: {
					required: true,
					minlength: this.props.appGetConfig && this.props.appGetConfig.users && this.props.appGetConfig.users.msisdn && this.props.appGetConfig.users.msisdn.minLength ? this.props.appGetConfig.users.msisdn.minLength : 9,
					maxLength: this.props.appGetConfig && this.props.appGetConfig.users && this.props.appGetConfig.users.msisdn && this.props.appGetConfig.users.msisdn.maxLength ? this.props.appGetConfig.users.msisdn.maxLength : 9,
					numeric: true
				},
				valid: false,
				touched: false,
				validationMessage: ''
			},
		}
	}

	updateField = (fieldToUpdate, newFieldState) => {
		this.setState({
			formData: {
				...this.state.formData,
				[fieldToUpdate]: newFieldState
			}
		})
	}

	handleSubmit = (e) => {
		logger.info('submit')
		e.preventDefault();


		let invalid = validateSubmit(this.state.formData);

		if(invalid) {
			this.setState({
				formData: invalid
			})
		} else {
			logger.info('valid')
			this.handleUserRemindPassword();
		}
	}

	handleUserRemindPassword = () => {
		let msisdn = this.state.formData.phone.value; //this.state.msisdn
		if(this.props.prefix) {
			msisdn = `${this.props.prefix.replace('+', '')}${this.state.formData.phone.value}`;
		}
		let uuid = this.props.authUuid;

		apiPost('/user/remind-password', { msisdn, uuid })
		  .catch((e) => {
			  this.setState({
				  isSubmitting: false
			  });
			  this.openModal(getText('Error'), getText('Phone number is not subscribed'));

			  return Observable.empty();
		  })
		  .mergeMap(res => {
			  let resp = Promise.resolve({ error: true });
			  if (res.ok) {
				  resp = res.json();
			  }

			  return Promise.resolve({ resp, statusCode: res.status });
		  })
		  .subscribe((result) => {
			  const { resp, statusCode } = result;

			  resp.then(response => {
				  if (response && !response.error) {
					  this.setState({
						  errorMsg: '',
						  isSubmitting: false
					  });

					  this.openModal(getText('Success'), getText('In a few moments you will receive an SMS with your username and password'), true, getText('Sign in'), false, false,() => {
						  this.props.uiToggleModal({}, { toggle: false }, {} );
						  this.props.uiRedirect({
							  pathname: '/'
						  })
					  });
				  } else {
					  this.setState({
						  errorMsg: response.error,
						  isSubmitting: false
					  });
					  let errorMsg = '';
					  switch (statusCode) {
						  case 400:
							  errorMsg = getText('Authentification service bad request, please try again later');
							  break;
						  case 401:
							  errorMsg = getText('Wrong username or password, please try again');
							  break;
						  case 403:
							  errorMsg = getText('Authentification service forbidden, please try again later');
							  break;
						  case 404:
							  errorMsg = getText('Authentification service not available, please try again later');
							  break;
						  case 500:
							  errorMsg = getText('Authentification service internal error, please try again later');
							  break;
						  case 502:
							  errorMsg = getText('Authentification service internal error, please try again later');
							  break;
						  case 503:
							  errorMsg = getText('Authentification service too busy, please try again later');
							  break;
						  default:
							  errorMsg = getText('Wrong username or password, please try again');
					  }
					  this.openModal(getText('Error'), errorMsg);
				  }
			  });
		  });
	};

	openModal = (title, message, button, buttonText, button2, button2Text, callback) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, button2, button2Text, callback  } );
	};

	render() {
		return (
		  <PromptComponent
			formData={this.state.formData}
			updateField={this.updateField}
			handleSubmit={this.handleSubmit}
		  />
		);
	}
}

export default PromptContainer;