import React, { Component } from 'react';
import Slider from "react-slick";
import connect from 'react-redux/es/connect/connect';
import clsx from "clsx";
import { HtmlParser } from 'AppUtils/language';
import { Link } from "react-router-dom";
import { motion } from "framer-motion"

import { userInfo, userNotifications, userNotificationsConsume, userRepositorySteps } from "../store/selectors";
import { uiToggleModal, uiRedirect } from 'AppStore/actions';
import {appGetInterfaceLang, uiGetSection, uiGetModal, appGetConfig} from "../../../store/selectors";
import {
	userLoadInfo, userLoadNotifications, userLoadNotificationsConsume,
	userLoadUpdateInfo, userLoadUpdateOnboarding, userRepositoryLoadSteps
} from "../store/actions";
import getText from 'AppUtils/language';
import { getValue } from 'AppUtils/objects';
import isEqual from "react-fast-compare";
import arrowPrev from './img/arrow-prev.png';
import arrowNext from './img/arrow-next.png';


const stateToProps = state => ({
	appGetConfig: appGetConfig(state),
	userInfo: userInfo(state),
	section: uiGetSection(state),
	notifications: userNotifications(state),
	userNotificationsConsume: userNotificationsConsume(state),
	lang: appGetInterfaceLang(state),
	uiGetModal: uiGetModal(state),
	userRepositorySteps: userRepositorySteps(state),
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	userLoadNotificationsConsume: (payload) => dispatch(userLoadNotificationsConsume(payload)),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	userLoadInfo: (payload) => dispatch(userLoadInfo(payload)),
	userLoadUpdateInfo: (payload) => dispatch(userLoadUpdateInfo(payload)),
	userLoadUpdateOnboarding: (payload) => dispatch(userLoadUpdateOnboarding(payload)),
	userRepositoryLoadSteps: (payload) => dispatch(userRepositoryLoadSteps(payload)),
});

const dialogVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.6
		}
	}
}

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
	  <img
		className={className}
		style={{ ...style }}
		onClick={onClick}
		src={arrowNext}
		alt=""/>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
	  <img
		className={className}
		style={{ ...style }}
		onClick={onClick}
		src={arrowPrev}
		alt=""/>
	);
}

const settings = {
	dots: false,
	arrows: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	nextArrow: <SampleNextArrow/>,
	prevArrow: <SamplePrevArrow/>,
	// adaptiveHeight: true,
};

const buttonVariants = {
	hidden: {
		opacity: 0
	},
	visible: {
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.2,
			delay: 0.6
		}
	}
}

@connect(stateToProps, actionsToProps)
class OnboardingContainer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			steps: '',
			info: null,
			onboardingStep: 1,
			onboardingSubstep: 1,
			currentSlide: 0,
			actions: [
				{
					substeps: [
					  {
						  action: "/profile-edit"
					  }
				  	]
				},
				{
					substeps: [
						{
							action: "/map"
						}
					]
				},
				{
					substeps: [
						{
							action: false
						},
						{
							action: false
						},
						{
							action: "/prizes"
						},
						{
							action: false
						},
						{
							action: "/map"
						},
					]
				},
				{
					substeps: [
						{
							action: props.appGetConfig?.features?.disableBuyCredits ? false : "/store/tickets"
						},
						{
							action: "/map"
						}
					]
				},
				{
					substeps: [
						{
							action: "/leaderboard"
						},
						{
							action: "/skills"
						}
					]
				},
				{
					substeps: [
						{
							action: "/tutorial"
						},
					]
				},
			]
		};
	}

	handleSubstepUpdate = (nextStep) => {
		if(nextStep+1 > this.state.onboardingSubstep) {
			this.props.userLoadUpdateOnboarding({ onboardingStep: this.state.onboardingStep, onboardingSubstep: nextStep+1 })
		}
	}

	openModal = (title, message, button, buttonText, button2, button2Text, restrictClose, callback, callback2) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, restrictClose, callback });
	};

	closeModal = () => {
		this.props.uiToggleModal({}, { toggle: false }, { title: '', message: '' });
	};

	componentDidUpdate(prevProps, prevState) {
		const onboardingStep = getValue(this.props, 'userInfo.settings.onboardingStep', 1);
		const onboardingSubstep = getValue(this.props, 'userInfo.settings.onboardingSubstep', 1);

		if (this.state.info == null && this.state.info != this.props.userInfo) {
			this.props.userRepositoryLoadSteps();
			this.setState({
				info: this.props.userInfo,
			});
		}

		if (this.state.onboardingStep != onboardingStep) {
			this.setState({
				onboardingStep,
			});
		}

		if (this.state.onboardingSubstep != onboardingSubstep) {
			this.setState({
				currentSlide: onboardingSubstep - 1,
				onboardingSubstep,
			});
		}

		if (this.state.steps != getValue(this.props, 'userRepositorySteps.list')) {
			this.setState({
				steps: this.props.userRepositorySteps.list,
			});
		}
	}

	componentDidMount() {
		const onboardingStep = getValue(this.props, 'userInfo.settings.onboardingStep', 1);
		const onboardingSubstep = getValue(this.props, 'userInfo.settings.onboardingSubstep', 1);

		if (this.props.userInfo != null) {
			this.props.userRepositoryLoadSteps();
			this.setState({
				info: this.props.userInfo,
			});
		}

		this.setState({
			currentSlide: onboardingSubstep - 1,
			onboardingStep,
			onboardingSubstep,
		});

		//this.props.userLoadUpdateOnboarding({ onboardingStep: 2, onboardingSubstep: 1 });
	}

	render() {
		const { onboardingStep, steps } = this.state;

		if(!onboardingStep || !steps) {
			return null;
		}

		return (
		  <motion.div
			className="slider-wrapper"
			variants={dialogVariants}
			initial="hidden"
			animate="visible"
		  >
			<Slider
			  {...settings}
			  initialSlide={this.state.onboardingSubstep-1}
			  className="slider-tutorial"
			  key={this.state.onboardingSubstep}
			  beforeChange={(current, next) => {
			  	this.setState({currentSlide: next})
			  	this.handleSubstepUpdate(next);
			  }}
			>
				{this.state.steps.length > 0 && this.state.steps[onboardingStep-1].substeps && this.state.steps[onboardingStep-1].substeps.map((substep, idx) => {

					return (
					  <>
						  <div key={idx} className="item">
							  <h3 style={{ position: 'relative' }}>
								  {substep.title[this.props.lang]}
							  </h3>
							  <p>{substep.description[this.props.lang]}</p>
						  </div>
					  </>
					)
				})}
			</Slider>
			{this.state.actions.length > 0 &&
			this.state.actions[onboardingStep-1].substeps.length > 0 &&
			this.state.actions[onboardingStep-1].substeps[this.state.currentSlide] &&
			this.state.actions[onboardingStep-1].substeps[this.state.currentSlide].action &&
			this.state.steps[onboardingStep-1].substeps[this.state.currentSlide].buttonText &&
			this.state.steps[onboardingStep-1].substeps[this.state.currentSlide].buttonText[this.props.lang] &&
				<motion.div
				  className="action-wrapper"
				  variants={buttonVariants}
				  initial="hidden"
				  animate="visible"
				>
					<Link to={this.state.actions[onboardingStep-1].substeps[this.state.currentSlide].action} className="skip">
						{getText(`${this.state.steps[onboardingStep-1].substeps[this.state.currentSlide].buttonText[this.props.lang]}`)}
					</Link>
				</motion.div>
			}
		</motion.div>);
	}
}

export default OnboardingContainer;