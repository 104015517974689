import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import getText from 'AppUtils/language';
import {Redirect} from 'react-router-dom';

import BalloonsComponent from "./BalloonsComponent";

import {appGetInterfaceLang} from "../../../../store/selectors";
import {gamesFinishBonus, gamesStartBonus} from "../../store/selectors";
import { uiRedirect, uiToggleModal } from "../../../../store/actions";
import {gamesLoadFinishBonus} from "../../store/actions";
import { getValue } from 'AppUtils/objects';
import isEqual from "lodash-es/isEqual";
import logger from 'AppUtils/logging';

const stateToProps = state => ({
	appGetInterfaceLang: appGetInterfaceLang(state),
	gamesStartBonus: gamesStartBonus(state),
	gamesFinishBonus: gamesFinishBonus(state),
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	gamesLoadFinishBonus: (data) => dispatch(gamesLoadFinishBonus(data)),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
});

@connect(stateToProps, actionsToProps)
class BalloonsContainer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			playId: getValue(this.props, 'location.state.playId'),
			notificationId: getValue(this.props, 'location.state.notificationId'),
			readyToOpen: false,
			opening: false,
			opened: false,
			showPrize: false,
			submited: false,
		}

		if(!this.state.playId) {
			this.props.uiRedirect({
				pathname: '/'
			})
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(!isEqual(prevProps.gamesFinishBonus, this.props.gamesFinishBonus) && this.props.gamesFinishBonus) {
			this.props.uiRedirect({
				pathname: '/'
			});
		}
	}


	handleVideoMounted = element => {
		// element.play();
		if (element !== null) {
			element.addEventListener('timeupdate', () => {
				logger.info(element.currentTime)

				if(element.currentTime > 2 && !this.state.opening && !this.state.opened) {
					logger.info('loop')
					this.setState({
						readyToOpen: true
					})
					element.currentTime = 1;
				} else {
					switch(this.state.opening) {
						case 1:
							element.currentTime = 6;
							this.setState({
								opening: false,
								opened: true
							})
							setTimeout(() => {
								element.pause();
							}, 1900)
							break;
						case 2:
							element.currentTime = 2;
							this.setState({
								opening: false,
								opened: true
							})
							setTimeout(() => {
								element.pause();
							}, 1900)
							break;
						case 3:
							element.currentTime = 4;
							this.setState({
								opening: false,
								opened: true
							})
							setTimeout(() => {
								element.pause();
							}, 1900)
							break;
						case 4:
							element.currentTime = 12;
							this.setState({
								opening: false,
								opened: true
							})
							setTimeout(() => {
								element.pause();
							}, 1900)
							break;
						case 5:
							element.currentTime = 10;
							this.setState({
								opening: false,
								opened: true
							})
							setTimeout(() => {
								element.pause();
							}, 1900)
							break;
						case 6:
							element.currentTime = 8;
							this.setState({
								opening: false,
								opened: true
							})
							setTimeout(() => {
								element.pause();
							}, 1900)
							break;
					}
				}
			})
		}
	};

	handlePick = (choice) => {
		if(!this.state.opening && !this.state.opened && this.state.readyToOpen && !this.state.showPrize) {
			this.setState({
				opening: choice,
				showPrize: true
			})
		}
	}

	openModal = (title, message, button, buttonText, button2, button2Text, restrictClose, callback, callback2) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, restrictClose, callback });
	};

	closeModal = () => {
		this.props.uiToggleModal({}, { toggle: false }, { title: '', message: '' });
	};

	handleRedeem = () => {
		if(!this.state.submited) {
			this.setState({
				submited: true
			}, () => {
				this.props.gamesLoadFinishBonus({playId: this.state.playId, notificationId: this.state.notificationId})
			})
		}

		if(this.props.gamesStartBonus.prize_type === 'instant') {
			this.openModal(getText('Info'), getText('Please check your phone in order to activate the prize!'), true, getText('Ok'), false, false, false, () => {
				this.closeModal();
			})
		}
	}

	render() {
		if(!this.props.gamesStartBonus) {
			return <Redirect to="/"/>;
		}

		return (
		  <BalloonsComponent
			playId={this.state.playId}
			notificationId={this.state.notificationId}
			lang={this.props.appGetInterfaceLang}
			showPrize={this.state.showPrize}
			handleVideoMounted={this.handleVideoMounted}
			handlePick={this.handlePick}
			gamesStartBonus={this.props.gamesStartBonus}
			gamesLoadFinishBonus={this.props.gamesLoadFinishBonus}
			uiRedirect={this.props.uiRedirect}
			handleRedeem={this.handleRedeem}
		  />
		);
	}
}

export default BalloonsContainer;