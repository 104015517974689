import React from 'react';
import { Switch, Route } from "react-router-dom";
import { getValue } from 'AppUtils/objects';
import getText from 'AppUtils/language';

import CheckAuth from "./containers/auth/CheckAuth";
import Layout from "./layout/Layout";
import HomeContainer from "./containers/home/HomeContainer";
import LineWordsContainer from "./containers/games/main-games/subcomponents/line-words/LineWordsContainer";
import QuizContainer from './containers/games/main-games/subcomponents/quiz/QuizContainer';
import WrongAnswersContainer from "./containers/games/main-games/subcomponents/wrong-answers/WrongAnswersContainer";
import SwipeItContainer from "./containers/games/main-games/subcomponents/swipe-it/SwipeItContainer";
import ConnectWordsContainer from "./containers/games/main-games/subcomponents/connect-words/ConnectWordsContainer";
import BonusGames from "./containers/games/bonus-games/BonusGames";
import GameContainer from "./containers/games/main-games/main/GameContainer";
import LuckyCageContainer from "./containers/games/bonus-games/lucky-cage/LuckyCageContainer";
import BalloonsContainer from './containers/games/bonus-games/balloons/BalloonsContainer';
import MapContainer from "./containers/map/MapContainer";
import LeaderboardContainer from "./containers/leaderboard/LeaderboardContainer";
import ProfileContainer from "./containers/profile/ProfileContainer";
import ProfileEditContainer from "./containers/profile/subcomponents/profile-edit/ProfileEditContainer";
import SkillsContainer from "./containers/skills/SkillsContainer";
import AchievementsContainer from "./containers/achievments/AchievementsContainer";
import AchievementsDetailsContainer
	from "./containers/achievments/subcomponents/achievements-detail/AchievementsDetailsContainer";
import StoreContainer from "./containers/store/StoreContainer";
import PrizesContainer from "./containers/prizes/PrizesContainer";
import WorldMapContainer from "./containers/world-map/WorldMapContainer";
import AuthContainer from "./containers/auth/main/AuthContainer";
import ResultGameContainer from "./containers/games/result-game/ResultGameContainer";
import FourZeroFour from './components/404/FourZeroFourComponent';
import TutorialContainer from "./containers/tutorial/TutorialContainer";

const Routes = (props) => {
	const { showMenu, toggleMenu } = props;

	return (
		  <Switch>
			  <Route path="/" exact>
				  <CheckAuth restricted={true}>
					  <Layout hasFooter={false} hasStats={true} hasSubheader={false} showMenu={props.showMenu} toggleMenu={props.toggleMenu}>
						  <HomeContainer />
					  </Layout>
				  </CheckAuth>
			  </Route>
			  <Route path="/challenge/instructions/:challengeId" exact render={(props) => (
				<CheckAuth restricted={true}>
					<Layout hasFooter={false} hasStats={true} hasSubheader={true} showMenu={showMenu} toggleMenu={toggleMenu} backLink="/map">
						<GameContainer {...props} />
					</Layout>
				</CheckAuth>
			  )}>
			  </Route>
			  <Route path="/challenge/swipeIt/:challengeId" exact render={(props) => (
				<CheckAuth restricted={true}>
					<Layout hasFooter={true} hasStats={true} hasSubheader={true} hasHint={true} showMenu={showMenu} toggleMenu={toggleMenu} gameType="swipeIt">
						<SwipeItContainer {...props} />
					</Layout>
				</CheckAuth>
			  )}>
			  </Route>
			  <Route path="/challenge/lineUp/:challengeId" exact render={(props) => (
				<CheckAuth restricted={true}>
					<Layout hasFooter={true} hasStats={true} hasSubheader={true} hasHint={true} showMenu={showMenu} toggleMenu={toggleMenu} gameType="lineUp">
						<LineWordsContainer {...props} />
					</Layout>
				</CheckAuth>
			  )}>
			  </Route>
			  <Route path="/challenge/matchIt/:challengeId" exact render={(props) => (
				<CheckAuth restricted={true}>
					<Layout hasFooter={true} hasStats={true} hasSubheader={true} hasHint={true} showMenu={showMenu} toggleMenu={toggleMenu} gameType="matchIt">
						<ConnectWordsContainer {...props} />
					</Layout>
				</CheckAuth>
			  )}>
			  </Route>
			  <Route path="/challenge/top3/:challengeId" exact render={(props) => (
				<CheckAuth restricted={true}>
					<Layout hasFooter={true} hasStats={true} hasSubheader={true} hasHint={true} showMenu={showMenu} toggleMenu={toggleMenu} gameType="top3">
						<WrongAnswersContainer {...props} />
					</Layout>
				</CheckAuth>
			  )}>
			  </Route>
			  <Route path="/bonus-challenge/lucky-cage" exact render={(props) => (
				<CheckAuth restricted={true}>
					<Layout hasFooter={true} hasStats={true} hasSubheader={true} title={getText('Bonus kit')} showMenu={showMenu} toggleMenu={toggleMenu} hideBack={true}>
						<LuckyCageContainer {...props} />
					</Layout>
				</CheckAuth>
			  )}>
			  </Route>
			  <Route path="/bonus-challenge/balloons" exact render={(props) => (
				<CheckAuth restricted={true}>
					<Layout hasFooter={true} hasStats={true} hasSubheader={true} title={getText('Bonus Pick')} showMenu={showMenu} toggleMenu={toggleMenu} hideBack={true}>
						<BalloonsContainer {...props} />
					</Layout>
				</CheckAuth>
			  )}>
			  </Route>
			  <Route path="/bonus-challenge/instructions" exact render={(props) => (
				<CheckAuth restricted={true}>
					<Layout hasFooter={false} hasStats={true} hasSubheader={true} showMenu={showMenu} toggleMenu={toggleMenu} hideBack={true}>
						<BonusGames {...props} />
					</Layout>
				</CheckAuth>
			  )}>
			  </Route>
			  <Route path="/map/:id" exact render={props =>
				<CheckAuth restricted={true}>
					<Layout hasFooter={false} hasStats={true} hasSubheader={false} showMenu={showMenu} toggleMenu={toggleMenu}>
						<MapContainer {...props} />
					</Layout>
				</CheckAuth>
			  }>
			  </Route>
			  <Route path="/map" exact render={props =>
				<CheckAuth restricted={true}>
					<Layout hasFooter={false} hasStats={true} hasSubheader={false} showMenu={showMenu} toggleMenu={toggleMenu}>
						<MapContainer {...props} />
					</Layout>
				</CheckAuth>
			  }>
			  </Route>
			  <Route path="/leaderboard">
				  <CheckAuth restricted={true}>
					  <Layout hasFooter={false} hasStats={false} hasSubheader={true} title={getText('Leaderboard')} showMenu={props.showMenu} toggleMenu={props.toggleMenu}>
						  <LeaderboardContainer />
					  </Layout>
				  </CheckAuth>
			  </Route>
			  <Route path="/profile">
				  <CheckAuth restricted={true}>
					  <Layout hasFooter={false} hasStats={false} hasSubheader={false} title={getText('Profile')} showMenu={props.showMenu} toggleMenu={props.toggleMenu}>
						  <ProfileContainer />
					  </Layout>
				  </CheckAuth>
			  </Route>
			  <Route path="/profile-edit">
				  <CheckAuth restricted={true}>
					  <Layout hasFooter={false} hasStats={false} hasSubheader={true} title={getText('Edit profile')} showMenu={props.showMenu} toggleMenu={props.toggleMenu} backLink="/profile">
						  <ProfileEditContainer />
					  </Layout>
				  </CheckAuth>
			  </Route>
			  <Route path="/skills">
				  <CheckAuth restricted={true}>
					  <Layout hasFooter={false} hasStats={false} hasSubheader={true} title={getText('Skills')} showMenu={props.showMenu} toggleMenu={props.toggleMenu}>
						  <SkillsContainer />
					  </Layout>
				  </CheckAuth>
			  </Route>
			  <Route path="/achievements/:slug?" render={(routeProps) => (<CheckAuth restricted={true}>
				  <AchievementsContainer {...props} {...routeProps} backLink="/profile" />
			  </CheckAuth>)} />
			  <Route path="/achievements-details/:id" render={(routeProps) => (<CheckAuth restricted={true}>
					  <AchievementsDetailsContainer {...props} {...routeProps} />
			  </CheckAuth>)} />
			  <Route path="/store/:slug?" render={(routeProps) => (<CheckAuth restricted={true}>
					  <StoreContainer {...props} {...routeProps} />
			  </CheckAuth>)} />
			  <Route path="/prizes">
				  <CheckAuth restricted={true}>
					  <Layout hasFooter={false} hasStats={false} hasSubheader={true} title={getText('Prizes')} showMenu={props.showMenu} toggleMenu={props.toggleMenu}>
						  <PrizesContainer />
					  </Layout>
				  </CheckAuth>
			  </Route>
			  <Route path="/world-map">
				  <CheckAuth restricted={true}>
					  <Layout hasFooter={false} hasStats={false} hasSubheader={true} title={getText('World map')} showMenu={props.showMenu} toggleMenu={props.toggleMenu}>
						  <WorldMapContainer />
					  </Layout>
				  </CheckAuth>
			  </Route>
			  <Route path="/result-game/:challengeId" render={(props) => (
				<CheckAuth restricted={true}>
					<Layout hasFooter={false} hasStats={true} hasSubheader={true} showMenu={showMenu} toggleMenu={toggleMenu}>
						<ResultGameContainer {...props} />
					</Layout>
				</CheckAuth>
			  )}>
			  </Route>
			  <Route path="/tutorial">
				  <CheckAuth restricted={true}>
					  <Layout hasFooter={false} hasStats={false} hasSubheader={true} title={getText('FAQ')} showMenu={props.showMenu} toggleMenu={props.toggleMenu}>
						  <TutorialContainer />
					  </Layout>
				  </CheckAuth>
			  </Route>
			  <Route path="/signin" render={props => <CheckAuth restricted={false}><AuthContainer {...props} /></CheckAuth>} />
			  <Route path="/signup" render={props => <CheckAuth restricted={false}><AuthContainer {...props} /></CheckAuth>} />
			  <Route path="/recover" render={props => <CheckAuth restricted={false}><AuthContainer {...props} /></CheckAuth>} />
			  <Route component={FourZeroFour} />
		  </Switch>
	);
};

export default Routes;