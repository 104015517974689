import React from 'react';
import { motion } from "framer-motion"
import getText from 'AppUtils/language';

import './Footer.scss';

const footerVariants = {
    hidden: {
        y: 60
    },
    visible: {
        y: 0,
        transition: {
            type: 'tween',
            duration: 0.3
        }
    }
}

const FooterComponent = (props) => {
    return (
        <motion.div
          className="footer"
          variants={footerVariants}
          initial="hidden"
          animate="visible"
        >
            <img src={props.prize?.image} alt="" className="prize-img"/>
            <p><strong>{getText('Grand Prize')}:</strong> {props.prize?.name[props.lang]}</p>
        </motion.div>
    );
};

export default FooterComponent;