import React, {Component} from 'react';

import SwipeItInstructionsComponent from "./SwipeItInstructionsComponent";

class SwipeItInstructionsContainer extends Component {

	state = {

	};


	render() {
		return (
		  <SwipeItInstructionsComponent
			imagesLoaded={this.props.imagesLoaded}
			handlePreloadImages={this.props.handlePreloadImages}
			challengePlay={this.props.challengePlay}
			handleStart={this.props.handleStart}
			lang={this.props.appGetInterfaceLang}
		  />
		);
	}
}

export default SwipeItInstructionsContainer;