import React from 'react';
import {Link} from "react-router-dom";
import { motion } from "framer-motion";
import getText from 'AppUtils/language';

import FormField from "../../../../components/form-field/FormField";

import prizeBmw from '../../main/img/prize-bmw.svg';
import prizePreview from '../../main/img/prize-picture.png';

import './Signin.scss';

const prizeVariants = {
    hidden: {
        scale: 0,
        opacity: 0,
    },
    visible: {
        scale: 1,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.2
        }
    }
}

const SigninComponent = (props) => {
    return (
        <div className="signin-component auth-component">
            <div className="form-container">
                <div className="content-wrapper-small">
                    <motion.div
                      className="prize-wrapper"
                      variants={prizeVariants}
                      initial="hidden"
                      animate="visible"
                    >
                        <img
                          className="prize-bg"
                          src={prizePreview}
                          alt=""
                        />
                        {/*<img*/}
                        {/*  className="prize"*/}
                        {/*  src={props.prize?.image}*/}
                        {/*  alt=""*/}
                        {/*/>*/}
                    </motion.div>
                    <h3 className="form-title">{props.prize?.name ? props.prize?.name[props.lang] : ''}</h3>

                    <form>
                        <div className="row-phone">
                            {props.prefix ?
                              <div className="prefix">
                                  <FormField
                                    element={props.formData.prefix}
                                    updateField={props.updateField}
                                    disabled={true}
                                  />
                              </div>
                              : null
                            }

                            <FormField
                              element={props.formData.phone}
                              updateField={props.updateField}
                            />
                        </div>
                        <FormField
                          element={props.formData.password}
                          updateField={props.updateField}
                        />
                        <div className="submit-input-group">
                            <button
                              type="submit"
                              className="submit-btn"
                              onClick={(e) => props.handleSubmit(e)}
                            >{getText('SIGN IN')}</button>
                        </div>
                    </form>
                    <Link to="/recover" className="forgot-pass-link">{getText('I forgot my password')}</Link>
                </div>
            </div>

            <div className="no-account">
                <p>{getText('Don’t have an account?')}</p>
                <div className="btns">
                    <Link to="/signup">{getText('SIGN UP')}</Link>
                    <Link to="#" onClick={props.handlePlayAsGuest}>{getText('PLAY AS GUEST')}</Link>
                </div>
            </div>
        </div>
    );
};

export default SigninComponent;