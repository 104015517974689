import React from 'react';
import clsx from "clsx";

import './Timer.scss';

const Timer = (props) => {
	return (
	  	<div className={clsx("timer", props.timeCritical && 'critical')}>
			<p>{props.timeLeft()}</p>
		</div>
	);
};

export default Timer;