import React from 'react';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Preload from "react-preload";
import { getValue } from 'AppUtils/objects';
import getText from 'AppUtils/language';

import LoaderPage from "../../../../components/loader-page/LoaderPage";
import Button from "../../../../components/button/Button";
import BoxShadow from "../../../../components/box-shadow/BoxShadow";
import FormField from "../../../../components/form-field/FormField";

import avatar1 from './img/avatar-1.svg';
import avatar2 from './img/avatar-2.svg';
import avatar3 from './img/avatar-3.svg';
import avatar4 from './img/avatar-4.svg';

import './ProfileEdit.scss';

const profileEditVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.2,
		}
	}
}

const ProfileEditComponent = (props) => {
	return (
	  <>
	  <LoaderPage isVisible={props.imagesLoaded && props.avatarsLoaded}/>
	  <Preload
		images={[]}
		autoResolveDelay={5000}
		// onError={}
		onSuccess={props.handlePreloadImages}
		resolveOnError={true}
		mountChildren={false}
	  >
	  </Preload>

	  {props.imagesLoaded && props.avatarsLoaded && (
		<motion.div
		  className="profile-edit-component"
		  variants={profileEditVariants}
		  initial="hidden"
		  animate="visible"
		>
			<div className="content-wrapper-small">
				<div className="edit-info-user">
					<FormField
					  element={props.formData.nickname}
					  updateField={props.updateField}
					/>

					<div className="form-field">
						<FormField
						  element={props.formData.avatar}
						  updateField={props.updateField}
						/>
					</div>
				</div>
			</div>

			<p className="separator-word">{getText('or')}</p>

			<div className="content-wrapper-small">
				<div className="edit-avatar-user">
					<p>{getText('Select your avatar from the list')}</p>
					<div className="avatars-list">
						{props.avatars && props.avatars.map((avatar, idx) => {
							return (<Link key={idx} to="#" onClick={() => props.onSelectAvatar(avatar)} className={`avatar-box ${props.selectedAvatar == avatar ? 'selected' : ''}`}>
								<BoxShadow>
									<img src={avatar} alt=""/>
								</BoxShadow>
							</Link>);
						})}
					</div>
				</div>
				<button
				  type="submit"
				  onClick={(e) => props.handleSubmit(e)}
				><Button disabled={props.isLoading} title={getText('SAVE')} slim={true}/></button>

			</div>
		</motion.div>
	  )}
	  </>
	);
};

export default ProfileEditComponent;